<template>
  <div>
    <Template>
      <v-card elevation="0" class="card-border-color">
        <v-card-title class="px-4 pt-3">
          <h3>{{ inEdicao ? 'Dados da nota' : 'Notas fiscais de serviço' }}</h3>
          <v-col cols="12">
            <v-divider></v-divider>
          </v-col>
        </v-card-title>

        <v-card-text class="pt-0">
          <v-row>
            <v-col cols="12">
              <Listagem :style="inEdicao ? 'display: none;' : ''" :refresh="refreshList"></Listagem>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </Template>
  </div>
</template>

<script>
import Template from "@/components/template/Template"
import Listagem from "@/components/documentosFiscais/nfse/Listagem"

export default {
  components: {Template, Listagem},
  name: "Nfse",
  data: () => ({
    inEdicao: false,
    refreshList: false
  }),
  watch: {
    $route(to) {
      this.inEdicao = to.hash === '#editar' || to.hash === '#criar';
      if (to.hash === '#criar') {
        this.$store.commit('nfse/setNfseEdicao', null);
      }
    }
  },
  mounted() {
    this.inEdicao = this.$route.hash === '#editar' || this.$route.hash === '#criar';
  }
}
</script>

<style scoped>

</style>