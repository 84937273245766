<template>
  <v-navigation-drawer
      permanent
      app
      :mini-variant.sync="mini"
      v-model="drawer"
      color="primary"
      :clipped="$vuetify.breakpoint.lgAndUp"
      :width="230"
  >
    <v-list-item class="pl-3 pr-0">
      <v-list-item-avatar rounded style="margin-right: 15px;" size="32px">
        <img src="@/assets/notinhaWhite.png"/>
      </v-list-item-avatar>
      <v-list-item-title style="margin-top: 3px;">
        <img src="@/assets/facilWhite.png" width="135px"/>
      </v-list-item-title>

      <v-btn icon @click="mini = !mini">
        <v-icon color="white">mdi-chevron-left</v-icon>
      </v-btn>
    </v-list-item>

    <v-divider></v-divider>
    <v-list-item class="pl-3" :alt="authData.usuario.nome">
      <v-list-item-avatar color="#99BC29" item size="32px" class="my-5">
        <v-icon color="primary" size="32px"> mdi-account</v-icon>
      </v-list-item-avatar>
      <v-list-item-content class="white--text">
        <v-list-item-title>{{ authData.usuario.nome }}</v-list-item-title>
        <v-list-item-subtitle class="white--text">{{ authData.usuario.email }}</v-list-item-subtitle>
      </v-list-item-content>
      <v-menu right v-if="isSuperOrSofthouseAdmin">
        <template v-slot:activator="{ on, attrs }">
          <v-btn dark icon v-bind="attrs" v-on="on">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item @click="goTo('/dados-empresa')" dense>
            <v-list-item-title>
              <v-icon small color="primary">mdi-store</v-icon>
              Dados da empresa
            </v-list-item-title>
          </v-list-item>

          <v-list-item @click="goTo('/certificado-digital')" dense>
            <v-list-item-title>
              <v-icon small color="primary">mdi-certificate-outline</v-icon>
              Certificado digital
            </v-list-item-title>
          </v-list-item>

          <v-divider></v-divider>

          <v-list-item @click.stop="logout()" dense>
            <v-list-item-title>
              <v-icon small color="primary">mdi-logout-variant</v-icon>
              Deslogar
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-list-item>
    <v-divider></v-divider>

    <v-list v-for="(item, idx) in items" dense :key="idx">
      <v-list-item router :to="item.to" :key="item.text" link
                   v-if="item.permission ? $can(item.permission[0], item.permission[1]) : true">
        <v-list-item-action style="margin-right: 20px;">
          <v-icon color="white">{{ item.icon }}</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title style="font-size: 14px" class="white--text">
            {{ item.text }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import {mapGetters} from "vuex";
import Alert from "../../util/alert";

export default {
  name: "MenuBar",
  data: () => ({
    drawer: null,
    mini: true,
  }),
  computed: {
    ...mapGetters({
      authData: "authentication/getAuthData",
      menuExpand: "core/getMenuExpand"
    }),

    items() {
      return [
        {
          icon: "mdi-view-dashboard-outline",
          text: "Dashboard",
          to: "/"
        },
        {
          icon: "mdi-store-outline",
          text: "Software House",
          to: "/softwarehouses",
          permission: ["ler", "softhouse"]
        },
        {
          icon: "mdi-map-marker-plus",
          text: "Homologações",
          to: "/solicitacao-homologacao",
          permission: ["ler", "suporte_chamado"]
        },
        {
          icon: "mdi-file-document-multiple",
          text: "NFS-e",
          to: "/nfse",
          permission: ["ler", "nfs"]
        },
        {
          icon: "mdi-account-group-outline",
          text: "Usuários",
          to: "/usuarios",
          permission: ["ler", "usuario"]
        },
        {
          icon: "mdi-connection",
          text: "Configurar Webservice",
          to: "/webservices",
          permission: ["all", "super_administrador"]
        },
        {
          icon: "mdi-database-marker-outline",
          text: "Cidades homologadas",
          to: "/cidades-homologadas"
        }
      ];
    },

    isSuperOrSofthouseAdmin() {
      return this.$can('all', 'softhouse_administrador') || this.$can('all', 'super_administrador');
    }
  },
  watch: {
    mini: function (val) {
      this.$store.dispatch("core/changeMenuExpand", val);
    }
  },
  methods: {
    logout() {
      Alert.confirm(
          "Deseja Fazer o Logout?",
          "Deseja Realmente realizar o Logout?"
      ).then((res) => {
        if (res.value) {
          this.$store.dispatch("authentication/logout").finally(() => {
            this.$router.push("/login");
          });
        }
      });
    },
    goTo(path) {
      if (this.isSuperOrSofthouseAdmin) {
        if (this.$route.path !== path) this.$router.push(path);
      }
    }
  },
  created() {
    this.mini = this.menuExpand;
  }
};
</script>

<style lang="scss" scoped>
$list-item-action-margin: 110px 100px !important;
$list-item-icon-margin: 200px 200px !important;
$list-item-action-icon-margin: 300px 300px !important;
</style>
