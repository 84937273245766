<template>
  <div>
    <v-form ref="form" v-model="isValidForm">

      <v-col cols="12 mt-5">
        <v-btn class="mr-5" color="secondary" elevation="2" @click="salvar" :loading="salvarLoading">Salvar</v-btn>
        <v-btn color="accent" elevation="2" outlined :disabled="salvarLoading" @click="cancelar">Cancelar</v-btn>
      </v-col>
    </v-form>
  </div>
</template>

<script>
export default {
  name: "Edicao",
  data: () => ({
    isValidForm: true,
    salvarLoading: false,
  }),
  computed: {

  },
  methods: {
    salvar() {
      this.$refs['form'].validate();
      if (this.isValidForm) {
        this.$emit('onUpdate');
        this.cancelar();
        this.salvarLoading = false;
      }
    },
    cancelar() {
      this.$router.push({hash: ''})
    },
  },
  mounted() {

  }
}
</script>

<style scoped>

</style>