export default {
    natureza_operacao: null,
    regime_tributacao: null,
    regime_especial_tributacao: null,
    aliquota_iss_sn: null,
    usuario_prefeitura: null,
    senha_prefeitura: null,
    receber_notificacao: false,
    email_notificacao: null,
    enviar_notificacao_cliente: false
};