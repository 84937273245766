import empresa from '@/api/empresa';

// initial state
const state = () => ({
    totalDeEmpresas: 0,
    empresas: [],
    empresaEdicao: null
});

// getters
const getters = {
    getEmpresas(state) {
        return state.empresas;
    },
    getTotalDeEmpresas(state) {
        return state.totalDeEmpresas;
    },
    getEmpresaEdicao(state) {
        return state.empresaEdicao;
    }
};

// actions
const actions = {
    minhaEmpresa({commit}) {
        return empresa.getMinhaEmpresa().then((result) => {
            commit('setEmpresaEdicao', result.data);
        }).catch((err) => {
            commit('setEmpresaEdicao', null);
            commit("core/setError", {
                titulo: 'Erro ao buscar a empresa',
                mensagem: err.data.message
            }, {root: true});
        });
    },
    salvar({commit}, payload) {
        return empresa.salvar(payload).finally(() => {
            commit('setEmpresaEdicao', null);
        });
    }
};

// mutations
const mutations = {
    setEmpresas(state, empresas) {
        state.empresas = empresas;
    },
    setTotalDeEmpresas(state, totalDeEmpresas) {
        state.totalDeEmpresas = totalDeEmpresas;
    },
    setEmpresaEdicao(state, empresaEdicao) {
        state.empresaEdicao = empresaEdicao;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};