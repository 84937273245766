<template>
  <v-breadcrumbs :items="path" divider="/">
    <v-breadcrumbs-item
        slot="item"
        slot-scope="{ item }"
        :disabled="item.disabled"
        :href="item.href"
        exact>
      {{ item.text }}
    </v-breadcrumbs-item>
  </v-breadcrumbs>
</template>

<script>
export default {
  name: "Breadcrumbs",
  data: () => ({
    path: []
  }),
  watch: {
    $route() {
      this.createPathBreadcrumb();
    },
  },
  methods: {
    createPathBreadcrumb() {
      const linkSplited = this.$route.path.split('/');
      const paths = [];

      linkSplited.forEach((item, idx, array) => {
        const hash = this.$route.hash.replace('#', '');
        const hasHash = hash !== '';
        if (idx === array.length - 1){
          paths.push({
            text: this.$route.name,
            disabled: !hasHash,
            href: this.$route.path
          });
          if (hasHash) {
            paths.push({
              text: hash.charAt(0).toUpperCase() + hash.slice(1),
              disabled: true,
              href: this.$route.path + '#' + hash
            });
          }
        } else {
          paths.push({
            text: item === '' ? 'Inicio' : item,
            disabled: false,
            href: '/',
          });
        }
      });
      this.path = paths;
    }
  },
  created() {
    this.createPathBreadcrumb();
  }
}
</script>

<style scoped>

</style>