import Vue from 'vue';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import router from './router';
import store from './store';
import $ from 'jquery';
import 'sweetalert2/dist/sweetalert2.min.css';
import VueLodash from 'vue-lodash';
import lodash from 'lodash';
import { abilitiesPlugin } from '@casl/vue';
import ability from './plugins/ability';
import interceptor from './interceptor/authInterceptor';
import Skeleton from 'vue-loading-skeleton';
import {mask} from 'vue-the-mask';
import money from 'v-money';

window.$ = $;
interceptor();

Vue.use({
  install: function(Vue){
    Vue.prototype.$jQuery = $; // you'll have this.$jQuery anywhere in your vue project
  }
});

Vue.use(VueLodash, {lodash: lodash});

Vue.use(abilitiesPlugin, ability, {
  useGlobalProperties: true
});

Vue.use(Skeleton);
Vue.config.productionTip = false;
Vue.directive('mask', mask);
Vue.use(money, {precision: 4});

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app');
