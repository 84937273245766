import TelefoneEntitie from './telefoneEntitie';
export default {
    id: null,
    telefone: {... TelefoneEntitie},
    nome: null,
    cpf: null,
    email: null,
    login: null,
    password: null,
    skype: null,
    status: true,
    role: null
};