import apiRequest from "@/util/apiRequest";

export default {
    getTodos() {
        return new Promise((resolve, reject) => {
            apiRequest.get('usuario/todos').then((response) => {
                resolve(response.data);
            }).catch((error) => {
                reject(error);
            });
        });
    },
    salvar(data) {
        return new Promise((resolve, reject) => {
            const isUpdate = data.id !== null;
            let link = 'usuario/salvar';
            if (isUpdate) {
                link = 'usuario/atualizar/' + data.id;
            }

            if (isUpdate) {
                apiRequest.put(link,data).then((response) => {
                    resolve(response.data);
                }).catch((error) => {
                    reject(error);
                });
            } else {
                apiRequest.post(link,data).then((response) => {
                    resolve(response.data);
                }).catch((error) => {
                    reject(error);
                });
            }
        });
    },
    remover(id) {
        return new Promise((resolve, reject) => {
            apiRequest.remove('usuario/deletar/'+id).then((response) => {
                resolve(response.data);
            }).catch((error) => {
                reject(error);
            });
        });
    }
};