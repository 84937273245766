<template>
    <v-footer padless absolute>
        <v-card flat tile width="100%" color="primary" class="text-center">
            <v-card-text class="white--text pa-2">
                © {{ new Date().getFullYear() }}
                Nota Fácil | Powered By
                <a class="light-green--text" href="https://vhsys.com.br" target="_blank">
                  <b>vh</b>sys
                </a> | Versão: {{ version }}
            </v-card-text>
        </v-card>
    </v-footer>
</template>

<script>
import { version } from "../../../package.json";

export default {
    padless: false,
    name: "Footer",
    data: () => ({
        version: version,
    }),
};
</script>

<style scoped>
.v-footer{
    border-top: 1px solid #e0e0e0;
}
</style>
