<template>
  <div>
    <Menu/>

    <v-container fluid>
      <Breadcrumbs></Breadcrumbs>
      <v-layout justify-center align-center class="mb-15">
        <v-col cols="11">
          <slot>
          </slot>
        </v-col>
      </v-layout>
    </v-container>

    <Footer/>
  </div>


</template>

<script>
import Menu from "./Menu"
import Footer from "./Footer";
import Breadcrumbs from "./Breadcrumbs";

export default {
  components: {Footer, Breadcrumbs, Menu},
  name: "Template",
  data: () => ({
    drawer: null
  })
}
</script>

<style scoped>

</style>
