<template>
  <div>
    <Template>
      <v-card elevation="0" class="card-border-color">
        <v-card-title class="px-4 pt-3">
          <h3>Cidades homologadas</h3>
          <v-col cols="12">
            <v-divider></v-divider>
          </v-col>
        </v-card-title>

        <v-card-text class="pt-0">
          <v-row>
            <v-col cols="12">
              <Listagem></Listagem>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </Template>
  </div>
</template>

<script>
import Template from "@/components/template/Template"
import Listagem from "@/components/cidadeHomologadas/Listagem"

export default {
  components: {Template, Listagem},
  name: "CidadesHomologadas",
  data: () => ({ })
}
</script>

<style scoped>

</style>