import chamados from '@/api/chamados';

// initial state
const state = () => ({
    totalChamados: 0,
    chamados: [],
    chamadoEdicao: null,
    chamadosMensagens: [],
    totalPaginasMensagens: 1
});

// getters
const getters = {
    getTotalChamados(state) {
        return state.totalChamados;
    },
    getChamados(state) {
        return state.chamados;
    },
    getChamadoEdicao(state) {
        return state.chamadoEdicao;
    },
    getChamadosMensagens(state) {
        return state.chamadosMensagens;
    },
    getTotalPaginasMensagens(state) {
        return state.totalPaginasMensagens;
    },
};

// actions
const actions = {
    buscarTodosChamados({commit}, payload) {
        return chamados.getTodosChamados(payload).then((result) => {
            commit('setChamados', result.data);
            commit('setTotalChamados', result.meta.total);
        }).catch(() => {
            commit('setChamados', []);
            commit('setTotalChamados', 0);
        });
    },
    async abrirChamado({commit}, payload) {
        try {
            const chamadoCreated = await chamados.abrirChamado(payload.chamado);
            await chamados.enviarMensagemChamado(chamadoCreated.data.id, payload.mensagem);
            return true;
        } catch (err) {
            commit("core/setError", {
                titulo: 'Erro ao salvar',
                mensagem: err.data.message
            }, {root: true});
            return false;
        }
    },
    encerrarChamado({commit}, payload) {
        return chamados.encerrarChamado(payload.idChamado).catch((err) => {
            commit("core/setError", {
                titulo: 'Erro ao buscar as mensagens',
                mensagem: err.data.message
            }, {root: true});
        });
    },
    buscarTodasMensagemsChamado({commit}, payload) {
        return chamados.getTodasMensagens(payload).then((result) => {
            commit('setChamadosMensagens', result.data);
            commit('setTotalPaginasMensagens', result.meta.last_page);
        }).catch(() => {
            commit('setChamadosMensagens', []);
            commit('setTotalPaginasMensagens', 0);
        });
    },
    enviarMensagemChamado({commit}, payload) {
        return chamados.enviarMensagemChamado(payload.idChamado, payload.mensagem).then((result) => {
            commit('pushChamadosMensagens', result.data);
        }).catch((err) => {
            commit("core/setError", {
                titulo: 'Erro ao buscar as mensagens',
                mensagem: err.data.message
            }, {root: true});
        });
    }
};

// mutations
const mutations = {
    setTotalChamados(state, value) {
        state.totalChamados = value;
    },
    setChamados(state, value) {
        state.chamados = value;
    },
    setChamadoEdicao(state, value) {
        state.chamadoEdicao = value;
    },
    setChamadosMensagens(state, value) {
        state.chamadosMensagens = value;
    },
    pushChamadosMensagens(state, value) {
        state.chamadosMensagens.unshift(value);
    },
    setTotalPaginasMensagens(state, value) {
        state.totalPaginasMensagens = value;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};