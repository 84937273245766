<template>
  <div>
    <v-row>
      <v-col cols="12" class="py-0">
        <ListagemOpcoes :adicionarNovo="true" />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-data-table
            :headers="headers"
            :items="items"
            :options.sync="options"
            :server-items-length="totalItems"
            :loading="loading"
            :items-per-page="15"
            @click:row="rowClick"
            item-key="id"
            locale="pt-BR"
            class="elevation-1">
          <v-progress-linear slot="progress" color="secondary" indeterminate class="v-progress-linear--absolute"></v-progress-linear>
        </v-data-table>
      </v-col>
    </v-row>


  </div>
</template>

<script>
import {mapGetters} from "vuex";
import ListagemOpcoes from "@/components/generic/ListagemOpcoes";

export default {
  name: "Listagem",
  components: {ListagemOpcoes},
  data: () => ({
    loading: false,
    options: {
      page: 1
    },
    headers: [
      {
        text: "#",
        value: "id",
        sortable: false,
        align: "left"
      },
      {
        text: "Empresa",
        value: "nome_razao_social",
        sortable: false,
        align: "center",
      },
      {
        text: "E-mail",
        value: "email",
        sortable: false,
        align: "center",
      },
      {
        text: "Status",
        value: "status",
        sortable: false,
        align: "center",
      },
      {
        text: "PDF",
        value: "pdf",
        align: "center"
      }
    ],
    items: [],
    totalItems: 0
  }),
  props: ['refresh'],
  computed: {
    ...mapGetters({
      // softwarehouses: "softwarehouse/getSoftwarehouses",
      // totalSoftwarehouses: "softwarehouse/getTotalSoftwarehouses"
    })
  },
  watch: {
    refresh: function () {
      this.buscarDados();
    },
    options: {
      handler(){
        this.buscarDados();
      },
      deep: true
    }
  },
  methods: {
    buscarDados() {
      // this.loading = true;
      // this.$store.dispatch("softwarehouse/buscarTodas", this.options).finally(() => this.loading = false)
    },
    rowClick() {
      // this.$store.commit('softwarehouse/setSoftwarehouseEdicao', row);
      this.$router.push({hash: '#editar'})
    }
  }
}
</script>

<style scoped>

</style>