<template>
  <div>
    <v-row>
      <v-col cols="12" class="py-0 pb-0 my-3" >
        <v-btn color="secondary" class="mr-2" elevation="2" href="#criar" v-if="adicionarNovo" small>
          <v-icon left>mdi-plus</v-icon>Adicionar
        </v-btn>
        <v-btn color="primary" x-small outlined  @click="abreFechaPainel" v-if="temFiltro && !filtroAberto">
          <v-icon left v-if="aberto">mdi-menu-up</v-icon>
          <v-icon left v-else>mdi-menu-down</v-icon>
          <v-badge :content="filtrados" bottom v-if="filtrados">Filtros</v-badge>
          <template v-else>
            Filtros
          </template>
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" class="py-0 pb-3">
        <v-expansion-panels v-model="filtroPanel">
          <v-expansion-panel>
            <v-expansion-panel-content class="filtro-panel">
              <div class="mt-10">
                <slot></slot>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "ListagemOpcoes",
  props: ['filtrados', 'temFiltro', 'adicionarNovo', 'filtroAberto'],
  data: () => ({
    filtroPanel: null,
    aberto: false
  }),
  methods: {
    abreFechaPainel () {
      if (this.aberto) {
        this.aberto = false;
        this.filtroPanel = null;
      } else {
        this.aberto = true;
        this.filtroPanel = 0;
      }
    }
  },
  beforeMount() {
    if (this.filtroAberto) {
      this.aberto = true;
      this.filtroPanel = 0;
    }
  }
}
</script>

<style scoped>
.filtro-panel {
  border-bottom: 2px solid #99BC28;
}
</style>