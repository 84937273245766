<template>
  <div>
    <v-col cols="12">
      <h3 class="text-uppercase">Endereço</h3>
      <v-col cols="12" class="mb-2">
        <v-divider></v-divider>
      </v-col>

      <v-row>
        <v-col cols="3" class="py-1">
          <v-text-field label="CEP" hint="Campo obrigatório" persistent-hint outlined dense
                        v-mask="'#####-###'" v-model="endereco.cep"
                        counter
                        :rules="[() => !!endereco.cep || 'Preenchimento obrigatório']"
                        maxlength="9" prepend-inner-icon="mdi-map-marker"/>
        </v-col>
        <v-col cols="3" ref="numero" class="py-1">
          <v-text-field label="Número" outlined dense v-model="endereco.numero" prepend-inner-icon="mdi-numeric"/>
        </v-col>
        <v-col cols="6" class="py-1">
          <v-text-field label="Complemento" v-model="endereco.complemento" counter maxlength="45" outlined dense/>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" class="py-1">
          <v-text-field label="Rua" hint="Campo obrigatório" persistent-hint
                        v-model="endereco.logradouro"
                        counter maxlength="255"
                        :rules="[() => !!endereco.logradouro || 'Preenchimento obrigatório']"
                        outlined dense/>
        </v-col>

      </v-row>

      <v-row>
        <v-col cols="6" class="py-1">
          <v-text-field label="Bairro" hint="Campo obrigatório" persistent-hint
                        counter maxlength="45"
                        :rules="[() => !!endereco.bairro || 'Preenchimento obrigatório']"
                        v-model="endereco.bairro" outlined dense/>
        </v-col>
        <v-col cols="6" class="py-1">
          <v-autocomplete
              hint="Campo obrigatório" persistent-hint
              :search-input.sync="cidadeAutoCompleteText"
              :items="cidadeAutoCompleteList"
              :loading="cidadeAutoCompleteLoading"
              :rules="[() => !!cidadeAutoCompleteText || 'Preenchimento obrigatório']"
              v-model="cidadeSelected"
              item-text="nome"
              item-value="codigo_ibge"
              outlined dense
              label="Cidade"
              no-data-text="Nenhuma cidade encontrada"
              prepend-inner-icon="mdi-city"
              return-object
          >
          </v-autocomplete>
        </v-col>
      </v-row>
    </v-col>
  </div>
</template>

<script>
import utilitarios from "@/api/utilitarios";

export default {
  name: "Endereco",
  props: {
    endereco: {
      ibge_cidade: '',
      logradouro: '',
      bairro: '',
      numero: '',
      complemento: '',
      cep: ''
    }
  },
  data: () => ({
    cidadeAutoCompleteText: '',
    cidadeAutoCompleteLoading: false,
    cidadeAutoCompleteList: [],
    cidadeSelected: {
      nome: '',
      codigo_ibge: ''
    }
  }),
  watch: {
    cidadeAutoCompleteText: function (value) {
      if (this.cidadeAutoCompleteLoading) {
        return;
      }

      if (value && value.length >= 3) {
        this.cidadeAutoCompleteLoading = true;
        this.buscarCidade(value);
      }
    },
    cidadeSelected: function (cidade) {
      this.endereco.ibge_cidade = cidade.codigo_ibge
    },
    'endereco.cep': function (value) {
      if (value.length >= 9) {
        this.buscarCep(value);
      }
    }
  },
  methods: {
    buscarCep(cep) {
      utilitarios.buscarCep(cep).then((result) => {
        this.cidadeAutoCompleteText = result.localidade;
        this.cidadeSelected = {
          nome: result.localidade,
          codigo_ibge: result.ibge
        };
        this.endereco.logradouro = result.logradouro;
        this.endereco.bairro = result.bairro;
      })
    },
    buscarCidade(cidade) {
      utilitarios.buscarCidade(cidade).then((res) => {
        this.cidadeAutoCompleteList = res.data;
      }).finally(() => {
        this.cidadeAutoCompleteLoading = false;
      });
    }
  }
}
</script>

<style scoped>

</style>