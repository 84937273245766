import EnderecoEntitie from "@/entities/enderecoEntitie";
import TelefoneEntitie from "@/entities/telefoneEntitie";

export default {
    id: null,
    nome_razao_social: null,
    nome_fantasia: null,
    cnpj_cpf: null,
    insc_estadual: null,
    insc_municipal: null,
    cnae_primario: null,
    email: null,
    responsavel: null,
    host: null,
    id_empresa_parceiro: null,
    cadastrarStaging: true,
    endereco_principal: {...EnderecoEntitie},
    telefone_principal: {...TelefoneEntitie},
    usuario_administrador: {
        nome: null,
        cpf: null,
        email: null,
        telefone: {
            ddd: null,
            numero: null,
            tipo_telefone: null
        },
        status: true,
        login: null,
        password: null
    }
};