<template>
  <div>
    <v-row>
      <v-col cols="12" class="py-0">
        <ListagemOpcoes :filtrados="totalFiltrado" :temFiltro="true" :filtroAberto="true">
          <v-row>
            <v-col cols="3" class="py-1">
              <v-autocomplete
                  :search-input.sync="cidadeAutoCompleteText"
                  :items="cidadeAutoCompleteList"
                  :loading="cidadeAutoCompleteLoading"
                  v-model="cidadeSelected"
                  item-text="nome"
                  item-value="codigo_ibge"
                  outlined dense
                  label="Cidade"
                  no-data-text="Nenhuma cidade encontrada"
                  return-object>
              </v-autocomplete>
            </v-col>
            <v-col cols="3" class="py-1">
              <v-text-field label="Webservice" v-model="filtro.padrao" outlined dense/>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" class="py-0 mb-2">
              <v-btn class="mr-5" color="primary" elevation="2" small :loading="loading" @click="filtrarDados(false)">
                Filtrar
              </v-btn>
              <v-btn color="default" elevation="2" outlined small :disabled="loading" @click="filtrarDados(true)">
                Limpar
              </v-btn>
            </v-col>
          </v-row>
        </ListagemOpcoes>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-data-table
            :headers="headers"
            :items="items"
            :options.sync="options"
            :server-items-length="totalItems"
            :loading="loading"
            :footer-props="{
              'items-per-page-options': [5, 10, 15, 30, 50]
            }"
            :items-per-page="15"
            item-key="id"
            locale="pt-BR"
            class="elevation-1">
          <v-progress-linear slot="progress" color="secondary" indeterminate class="v-progress-linear--absolute"></v-progress-linear>
        </v-data-table>
      </v-col>
    </v-row>

  </div>
</template>

<script>
import {mapGetters} from "vuex";
import ListagemOpcoes from "@/components/generic/ListagemOpcoes";
import utilitarios from "@/api/utilitarios";
import {totalFiltrado} from "@/util/util";
import _ from "lodash";

export default {
  name: "Listagem",
  components: {ListagemOpcoes},
  data: () => ({
    totalFiltrado: null,
    filtro: {
      nome: null,
      padrao: null
    },
    cidadeAutoCompleteText: '',
    cidadeAutoCompleteLoading: false,
    cidadeAutoCompleteList: [],
    cidadeSelected: {
      nome: '',
      codigo_ibge: ''
    },
    loading: false,
    options: {
      page: 1,
      itemsPerPage: 15
    },
    headers: [
      {
        text: "#",
        value: "id",
        sortable: false,
        align: "left"
      },
      {
        text: "Cidade",
        value: "cidade.nome",
        sortable: false,
        align: "left",
      },
      {
        text: "Webservice",
        value: "webservice.padrao",
        sortable: false,
        align: "left",
      },
      {
        text: "IBGE",
        value: "cidade.codigo_ibge",
        align: "left"
      },
      {
        text: "Estado",
        value: "cidade.estado.estado",
        sortable: false,
        align: "left",
      }
    ]
  }),
  computed: {
    ...mapGetters({
      items: "cidades/getCidadesHomologadas",
      totalItems: "cidades/getTotalCidadesHomologadas"
    })
  },
  watch: {
    cidadeAutoCompleteText: function (value) {
      if (this.cidadeAutoCompleteLoading) {
        return;
      }

      if (value && value.length >= 3) {
        this.cidadeAutoCompleteLoading = true;
        this.buscarCidade(value);
      }
    },
    cidadeSelected: function (cidade) {
      this.filtro.nome = cidade.nome;
    },
    options: {
      handler(){
        this.buscarDados();
      },
      deep: true
    }
  },
  methods: {
    buscarDados() {
      this.loading = true;
      const filtragem = _.cloneDeep(this.options);
      filtragem.filtro = _.cloneDeep(this.filtro);
      this.$store.dispatch("cidades/buscarTodasCidadesHomologadas", filtragem).finally(() => this.loading = false)
    },
    filtrarDados(clear) {
      this.totalFiltrado = totalFiltrado(this.filtro);
      if (clear) {
        this.filtro = {
          nome: null,
          padrao: null
        }
        if (this.totalFiltrado !== null) {
          this.totalFiltrado = null;
        }
      }

      this.buscarDados();
    },
    buscarCidade(cidade) {
      utilitarios.buscarCidade(cidade).then((res) => {
        this.cidadeAutoCompleteList = res.data;
      }).finally(() => {
        this.cidadeAutoCompleteLoading = false;
      });
    }
  }
}
</script>

<style scoped>

</style>