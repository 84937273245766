import apiRequest from "@/util/apiRequest";

export default {
    salvar(data) {
        return new Promise((resolve, reject) => {
            apiRequest.post('certificado/salvar',data).then((response) => {
                resolve(response.data);
            }).catch((error) => {
                reject(error);
            });
        });
    },
    remover(id) {
        return new Promise((resolve, reject) => {
            apiRequest.remove('certificado/deletar/'+id).then((response) => {
                resolve(response.data);
            }).catch((error) => {
                reject(error);
            });
        });
    }
};