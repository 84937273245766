<template>
  <div>
    <v-form ref="form" v-model="isValidForm">
      <v-col cols="12">
        <v-row>
          <v-col cols="6" class="py-1">
            <v-text-field label="Razão Social" hint="Campo obrigatório"
                          persistent-hint outlined dense prepend-inner-icon="mdi-store"
                          v-model="empresa.nome_razao_social"
                          counter maxlength="60"
                          :rules="[validations.required]"/>
          </v-col>
          <v-col cols="6" class="py-1">
            <v-text-field label="Nome Fantasia" counter maxlength="60" outlined dense v-model="empresa.nome_fantasia"/>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4" class="py-1">
            <v-text-field label="CPF/CNPJ" hint="Campo obrigatório" persistent-hint outlined dense
                          v-model="empresa.cnpj_cpf" counter maxlength="18"
                          v-mask="['###.###.###-##','##.###.###/####-##']"
                          :rules="[validations.required]"/>
          </v-col>
          <v-col cols="3" class="py-1">
            <v-text-field label="Inscrição Estadual" outlined dense counter maxlength="45"
                          v-model="empresa.insc_estadual"/>
          </v-col>
          <v-col cols="3" class="py-1">
            <v-text-field label="Inscrição Municipal" outlined dense counter maxlength="45"
                          v-model="empresa.insc_municipal"/>
          </v-col>
          <v-col cols="2" class="py-1">
            <v-text-field label="Cnae Primário" outlined dense counter maxlength="7" v-model="empresa.cnae_primario"/>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4" class="py-1">
            <v-select
                :items="[
                      { text: 'Simples Nacional', value: 1 },
                      { text: 'Simples Nacional, excesso sublimite de receita bruta', value: 2 },
                      { text: 'Regime Normal.', value: 3 }
                      ]"
                item-value="value"
                item-text="text"
                v-model="empresa.regime_tributario"
                :rules="[validations.required]"
                dense
                outlined
                label="Regime tributario"
                name="tipo_solicitacao"></v-select>
          </v-col>
          <v-col cols="4" class="py-1">
            <v-select
                :items="[
                      { text: 'Microempresa Municipal', value: 1 },
                      { text: 'Estimativa', value: 2 },
                      { text: 'Sociedade de Profissionais', value: 3 },
                      { text: 'Cooperativa', value: 4 },
                      { text: 'Microempresário Individual', value: 5 },
                      { text: 'Microempresário e Empresa de Pequeno Porte', value: 6 }
                      ]"
                item-value="value"
                item-text="text"
                v-model="empresa.regime_especial_tributario"
                dense
                outlined
                label="Regime especial tributario"
                name="tipo_solicitacao"></v-select>
          </v-col>
          <v-col cols="4" class="py-1">
            <v-text-field label="Inscrição Estadual ST" outlined dense counter maxlength="14"
                          v-model="empresa.insc_estadual_st"/>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4" class="py-1">
            <v-text-field label="Responsável" outlined dense counter maxlength="255" v-model="empresa.responsavel"/>
          </v-col>
          <v-col cols="3" class="py-1">
            <v-text-field label="Email" hint="Campo obrigatório" persistent-hint outlined dense
                          v-model="empresa.email" counter maxlength="400"
                          :rules="[validations.required]"/>
          </v-col>
          <v-col cols="2" class="py-1">
            <v-text-field label="DDD" hint="Campo obrigatório" persistent-hint outlined dense
                          v-model="empresa.telefone_principal.ddd" counter maxlength="2"
                          :rules="[validations.required]"/>
          </v-col>
          <v-col cols="3" class="py-1">
            <v-text-field label="Número" hint="Campo obrigatório" persistent-hint outlined dense prepend-inner-icon="mdi-phone"
                          v-model="empresa.telefone_principal.numero" counter maxlength="10"
                          :rules="[validations.required]"/>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="6" class="py-1">
            <v-text-field label="Webhook" v-model="empresa.url_retorno" outlined dense hint="Link do seu sistema que receberá notitificações do Nota Fácil" persistent-hint/>
          </v-col>
          <v-col cols="6" class="py-1">
            <v-text-field label="Host" outlined dense prepend-inner-icon="mdi-cloud" v-model="empresa.host"/>
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="12">
        <h3 class="text-uppercase">Dados NFC-e</h3>
        <v-col cols="12" class="mb-2">
          <v-divider></v-divider>
        </v-col>

        <v-row>
          <v-col cols="4" class="py-1">
            <v-text-field label="ID CSC Homologação" v-model="empresa.id_csc_homologacao" outlined dense counter maxlength="11"/>
          </v-col>
          <v-col cols="8" class="py-1">
            <v-text-field label="CSC Homologação" v-model="empresa.csc_homologacao"
                          :append-icon="passwordCscHomolog ? 'mdi-eye' : 'mdi-eye-off'"
                          :type="passwordCscHomolog ? 'text' : 'password'"
                          @click:append="passwordCscHomolog = !passwordCscHomolog"
                          outlined dense counter maxlength="65"/>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4" class="py-1">
            <v-text-field label="ID CSC Produção" v-model="empresa.id_csc_producao" outlined dense counter maxlength="11"/>
          </v-col>
          <v-col cols="8" class="py-1">
            <v-text-field label="CSC Produção" v-model="empresa.csc_producao"
                          :append-icon="passwordCscProd ? 'mdi-eye' : 'mdi-eye-off'"
                          :type="passwordCscProd ? 'text' : 'password'"
                          @click:append="passwordCscProd = !passwordCscProd"
                          outlined dense counter maxlength="65"/>
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="12">
        <h3 class="text-uppercase">Dados NFS-e</h3>
        <v-col cols="12" class="mb-2">
          <v-divider></v-divider>
        </v-col>

        <v-row>
          <v-col cols="4" class="py-1">
            <v-text-field label="Natureza operação" v-model="empresa.nfs_config.natureza_operacao" outlined dense counter maxlength="15"/>
          </v-col>
          <v-col cols="4" class="py-1">
            <v-text-field label="Usuário prefeitura" v-model="empresa.nfs_config.usuario_prefeitura" outlined dense counter maxlength="65"/>
          </v-col>
          <v-col cols="4" class="py-1">
            <v-text-field label="Senha prefeitura" v-model="empresa.nfs_config.senha_prefeitura"
                          outlined dense counter maxlength="65"
                          :append-icon="passwordShow ? 'mdi-eye' : 'mdi-eye-off'"
                          :type="passwordShow ? 'text' : 'password'"
                          @click:append="passwordShow = !passwordShow"/>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="4" class="py-1" v-if="empresa.nfs_config.receber_notificacao">
            <v-text-field label="Email para notificação"
                          v-model="empresa.nfs_config.email_notificacao" :rules="[validations.required]"
                          hint="Campo obrigatório" persistent-hint outlined dense/>
          </v-col>
          <v-col cols="4" class="py-1">
            <v-tooltip bottom transition="fade-transition" >
              <template v-slot:activator="{ on, attrs }">
                <span v-on="on" v-bind="attrs">
                      <v-checkbox class="my-1" label="Receber NFS-e por e-mail" v-model="empresa.nfs_config.receber_notificacao"/>
                </span>
              </template>
              <span>
                Receber e-mails para qualquer alteração de status das NFS-e da empresa
              </span>
            </v-tooltip>
          </v-col>
          <v-col cols="4" class="py-1">
            <v-tooltip bottom transition="fade-transition">
              <template v-slot:activator="{ on, attrs }">
                <span v-on="on" v-bind="attrs">
                      <v-checkbox class="my-1" label="Enviar NFS-e por e-mail para o cliente" v-model="empresa.nfs_config.enviar_notificacao_cliente"/>
                </span>
              </template>
              <span>
                Enviar e-mail para o cliente da nota quando ela for emitida/cancelada.
              </span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-col>

      <Endereco :endereco="empresa.endereco_principal"></Endereco>

      <v-col cols="12 mt-5">
        <v-btn class="mr-5" color="secondary" elevation="2" @click="salvar" :loading="salvarLoading">Salvar</v-btn>
        <v-btn color="accent" elevation="2" outlined :disabled="salvarLoading" @click="cancelar" v-if="!fromMeusDados">Cancelar</v-btn>
      </v-col>
    </v-form>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import _ from "lodash";
import EmpresaEntitie from '@/entities/empresaEntitie';
import NfseConfigEntitie from "@/entities/nfseConfigEntitie";
import Endereco from "@/components/generic/Endereco";
import Validator from "@/util/validation";

export default {
  name: "Edicao",
  components: {Endereco},
  props: ['fromMeusDados'],
  data: () => ({
    isValidForm: true,
    salvarLoading: false,
    empresa: {... EmpresaEntitie},
    validations: {...Validator},
    passwordShow: false,
    passwordCscHomolog: false,
    passwordCscProd: false
  }),
  computed: {
    ...mapGetters({
      empresaEdicao: "empresa/getEmpresaEdicao"
    })
  },
  watch: {
    'empresa.regime_tributario': function (value) {
      this.empresa.nfs_config.regime_tributacao = value;
    },
    'empresa.regime_especial_tributario': function (value) {
      this.empresa.nfs_config.regime_especial_tributacao = value;
    }
  },
  methods: {
    salvar() {
      this.$refs['form'].validate();
      if (this.isValidForm) {
        this.salvarLoading = true;
        this.$store.dispatch('empresa/salvar', this.empresa).then(() => {
          this.$store.commit('core/setSuccess', 'Atualizado')
        }).finally(() =>{
          this.salvarLoading = false;
        });
      }
    },
    cancelar() {
      this.$router.push({hash: ''});
    },
    loadEmpresaEdicao() {
      const edicao = _.cloneDeep(this.empresaEdicao);
      if (edicao.endereco) {
        edicao.endereco_principal = edicao.endereco;
        delete edicao.endereco;
      }
      if (edicao.nfs_config) {
        if (_.isEmpty(edicao.regime_tributario) && !_.isEmpty(edicao.nfs_config.regime_tributacao)) {
          edicao.regime_tributario = edicao.nfs_config.regime_tributacao;
        }

        if (_.isEmpty(edicao.regime_especial_tributario) && !_.isEmpty(edicao.nfs_config.regime_especial_tributacao)) {
          edicao.regime_especial_tributario = edicao.nfs_config.regime_especial_tributacao
        }
      } else {
        edicao.nfs_config = {... NfseConfigEntitie}
      }

      this.empresa = edicao;
    }
  },
  mounted() {
    if (this.empresaEdicao) {
      this.loadEmpresaEdicao();
    }
  }
}
</script>

<style scoped>

</style>