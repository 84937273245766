import usuarios from '@/api/usuarios';

// initial state
const state = () => ({
    totalUsuarios: 0,
    usuarios: [],
    usuarioEdicao: null
});

// getters
const getters = {
    getUsuarios(state) {
        return state.usuarios;
    },
    getTotalUsuarios(state) {
        return state.totalUsuarios;
    },
    getUsuarioEdicao(state) {
        return state.usuarioEdicao;
    }
};

// actions
const actions = {
    buscarTodas({commit}, payload) {
        return usuarios.getTodos(payload).then((result) => {
            commit('setUsuarios', result.data);
            commit('setTotalUsuarios', result.meta.total);
        }).catch((err) => {
            commit("core/setError", {
                titulo: 'Erro ao buscar as usuários',
                mensagem: err.data.message
            }, {root: true});
        });
    },
    salvar({commit}, payload) {
        return usuarios.salvar(payload).catch((err) => {
            commit("core/setError", {
                titulo: 'Erro salvar o usuário',
                mensagem: err.data.message
            }, {root: true});
        });
    },
    remover({commit}, payload) {
        return usuarios.remover(payload.id).then((data) => {
            commit('removerUsuario', payload);
            commit('core/setSuccess', data.mensagem, {root: true});
        }).catch((err) => {
            commit("core/setError", {
                titulo: 'Erro remover o usuário',
                mensagem: err.data.message
            }, {root: true});
        });
    }
};

// mutations
const mutations = {
    setUsuarios(state, usuarios) {
        state.usuarios = usuarios;
    },
    setTotalUsuarios(state, totalUsuarios) {
        state.totalUsuarios = totalUsuarios;
    },
    setUsuarioEdicao(state, usuarioEdicao) {
        state.usuarioEdicao = usuarioEdicao;
    },
    removerUsuario(state, usuario) {
        const index = state.usuarios.findIndex(c => c.id === usuario.id);
        state.usuarios.splice(index, 1);
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};