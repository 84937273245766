<template>
  <v-row>
    <v-col cols="12" class="mt-0">
      <v-col cols="12">
        <v-col cols="12" class="mt-0">
          <h3 class="text-uppercase">Mensagens</h3>

        </v-col>
      </v-col>


      <v-col cols="12">

        <v-col cols="12" class="py-2">
          <vue-editor v-model="mensagemEntities.mensagem"/>
        </v-col>
        <v-col cols="12">
          <v-file-input
              counter
              multiple
              label="Upload de Arquivos"
              v-model="arquivoAnexo"
          ></v-file-input>
        </v-col>

        <v-col cols="12">
          <v-btn color="secondary" @click="salvarMensagem()" :loading="loading">
            Enviar
          </v-btn>
        </v-col>
      </v-col>


      <v-progress-linear
          indeterminate
          color="green"
          v-if="loading === true"
      ></v-progress-linear>
      <v-col cols="12">
        <v-row>
          <v-col cols="12" v-for="(item, i) in mensagens" v-bind:key="i">

            <v-col cols="12" class="float-left" v-if="!item.notafacil_suporte">

              <v-col cols="6" class="float-left">
                <div class="ml-1 mb-1">
                  <v-icon size="14px" class="mr-2 corAzul">
                    mdi-circle
                  </v-icon>
                  <span>{{ item.nome_usuario }}</span>
                  <span class="float-right ml-3">
                      <span v-if="mensagens[i].mensagem_lida !== 0">
                        <v-tooltip bottom color="secondary">
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon class="pr-1" color="secondary" small v-bind="attrs" v-on="on">
                                  mdi-check-all
                                </v-icon>
                            </template>
                            <span>Mensagem lida! </span>
                            <span>ID: {{ mensagens[i].id }}</span>
                        </v-tooltip>
                      </span>
                    </span>
                  <span class="float-right">{{ formatarData(mensagens[i].updated_at) }}</span>
                </div>

                <div class="blueNotaFacil">
                  <label style="line-height: 33px">
                    <p v-html="item.mensagem"/>
                  </label>
                </div>

                <v-col cols="12" align="left" v-show="item.arquivos.length !== 0">
                  <v-row >
                  <template v-for="(file, index) in item.arquivos">

                      <v-col v-bind:key="index"  cols="6">
                      <v-chip class="mr-1" link :key="index" :href="file.link_download">
                        <v-icon class="mr-2">mdi-paperclip</v-icon>
                        {{ file.nome_arquivo }}
                      </v-chip>

                    </v-col>

                  </template>
                  </v-row>
                </v-col>


              </v-col>
            </v-col>

            <v-col cols="12" class="float-right" v-else>
              <v-col cols="6" class="float-right">
                <div class="mr-3 mb-3">
                  <div class="float-right">
                    <span>{{ item.nome_usuario }}</span>
                    <v-icon size="14px" class="ml-2 corVerde">
                      mdi-circle
                    </v-icon>
                  </div>
                  <span class="mr-3">
                      <span v-if="mensagens[i].mensagem_lida !== 0">
                        <v-tooltip bottom color="secondary">
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon class="pr-1" color="secondary" small v-bind="attrs" v-on="on">
                                  mdi-check-all
                                </v-icon>
                            </template>
                            <span>Mensagem lida! </span>
                            <span>ID: {{ mensagens[i].id }}</span>
                        </v-tooltip>
                      </span>
                    </span>
                  <span>{{ formatarData(mensagens[i].updated_at) }}</span>
                </div>

                <div class="greenNotaFacil">
                  <label style="line-height: 33px">
                    <p v-html="item.mensagem"/>
                  </label>
                </div>

                <v-col cols="12" align="left" v-show="item.arquivos.length !== 0" >
                  <template v-for="(file, index) in item.arquivos">
                    <v-col cols="12" v-bind:key="index">
                      <v-chip class="mr-1" link :key="index" :href="file.link_download">
                        <v-icon class="mr-2" >mdi-paperclip</v-icon>
                        {{ file.nome_arquivo }}
                      </v-chip>
                    </v-col>
                  </template>
                </v-col>

              </v-col>
            </v-col>

          </v-col>

        </v-row>
        <v-col cols="12" class="text-center">
          <v-pagination :length="totalPaginasMensagens" v-model="mensagensPage" v-if="totalPaginasMensagens > 0"></v-pagination>
        </v-col>

      </v-col>
    </v-col>
  </v-row>


</template>

<script>

import {formatDate} from "@/util/util";
import MensagemEntities from "@/entities/mensagemEntities";
import {VueEditor} from "vue2-editor";
import {mapGetters} from "vuex";
import {toBase64} from "../../util/util";

export default {
  name: "Mensagens",
  props: ['idSolicitacao', 'refresh'],
  components: {VueEditor},

  data: () => ({
    mensagemEntities: {...MensagemEntities},
    arquivoAnexo: [],
    loading: false,
    mensagensPage: 1
  }),
  mounted() {
    this.buscarDados()
  },
  computed: {
    ...mapGetters({
      mensagens: "mensagem/getMensagens",
      totalMensagens: "mensagem/getTotalMensagens",
      totalPaginasMensagens: 'mensagem/getTotalPaginas'
    })
  },
  watch: {
    arquivoAnexo: async function (value) {
      for (const file of value) {
        let splitArquivo = (file.name).split(".");

        this.mensagemEntities.arquivos.push({
          nome: file.name,
          extensao: splitArquivo[1],
          base64: await toBase64(file)
        })
      }
    },
    refresh: function () {
      this.buscarDados()
    },
    mensagensPage: function () {
      this.buscarDados();
    },
    options: {
      handler() {
        this.buscarDados()
      },
      deep: true
    }
  },
  methods: {
    salvarMensagem() {
      this.loading = true;
      if (this.arquivoAnexo == 0) {
        delete this.mensagemEntities.arquivos;
      }

      this.$store.dispatch('mensagem/salvar', {
        idSolicitacao: this.idSolicitacao,
        mensagems: this.mensagemEntities
      }).finally(() => {
        this.mensagemEntities.mensagem = ""
        this.mensagemEntities.arquivos = []
        this.mensagemEntities.mensagem_lida = 0
        this.arquivoAnexo = []
        this.loading = false;
      })
    },

    buscarDados() {

      this.loading = true;
      this.$store.dispatch("mensagem/buscarTodasMensagens", {
        idSolicitacao: this.idSolicitacao,
        page: this.mensagensPage
      }).finally(() => {
        this.loading = false
      });
    },

    formatarData(data) {
      return formatDate(data);
    },
  }
}
</script>

<style scoped>
.v-application p {
  margin-bottom: 0 !important;
}

.blueNotaFacil {
  background-color: #235274;
  border-radius: 20px;
  color: white;
  font-size: 16px;
  padding: 20px;
  font-weight: bold;
}

.greenNotaFacil {
  background-color: #99bc27;
  border-radius: 20px;
  color: white;
  font-size: 16px;
  padding: 20px;
  font-weight: bold;
}

.corAzul {
  color: #235274;
}

.corVerde {
  color: #99bc27;
}

.box {
  border: 2px solid #dde4eb;

  /* background: #cdd5dd; */
  padding-bottom: 0px;
  font-weight: 900;
  color: #3275a6;
  position: relative;
  border-radius: 8px;
}
</style>