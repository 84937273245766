import authentication from '@/api/authentication';

// initial state
const state = () => ({
    authData: null
});

// getters
const getters = {
    getAuthData(state) {
        return state.authData;
    }
};

// actions
const actions = {
    login({commit}, payload) {
        return new Promise((resolve, reject) => {
            authentication.postLogin(payload.login, payload.password).then((result) => {
                commit('setAuthData', result);
                resolve(result);
            }).catch((err) => {
                commit("core/setError", {
                    titulo: 'Erro ao autenticar',
                    mensagem: err.data.message
                }, {root: true});
                reject(err);
            });
        });

    },
    logout() {
        return authentication.getLogout().finally(() => {
            window.localStorage.removeItem("sped-api");
        });
    }
};

// mutations
const mutations = {
    setAuthData(state, authData) {
        state.authData = authData;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};