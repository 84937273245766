import softwarehouse from '@/api/softwarehouse';
import billing from '@/api/billing';
import moment from "moment";
moment.locale("pt-br");

// initial state
const state = () => ({
    totalSoftwarehouses: 0,
    softwarehouses: [],
    softwarehouseEdicao: null
});

// getters
const getters = {
    getSoftwarehouses(state) {
        return state.softwarehouses;
    },
    getTotalSoftwarehouses(state) {
        return state.totalSoftwarehouses;
    },
    getSoftwarehouseEdicao(state) {
        return state.softwarehouseEdicao;
    }
};

// actions
const actions = {
    buscarTodas({commit}, payload) {
        return softwarehouse.getTodas(payload).then((result) => {
            commit('setSoftwarehouses', result.data);
            commit('setTotalSoftwarehouses', result.meta.total);
        }).catch((err) => {
            commit("core/setError", {
                titulo: 'Erro ao buscar as softwarehouses',
                mensagem: err.message
            }, {root: true});
        });
    },
    async salvar({commit}, payload) {
        try {
            const isUpdate = payload.id !== null;
            const softwareSaved = await softwarehouse.salvar(payload);
            if (!isUpdate) {
                billing.cadastrar({
                    empresa_id: softwareSaved.data.id,
                    servicos_id: 1,
                    qtd_creditos: 100,
                    creditos_ilimitados: true,
                    qtd_cnpj: 10,
                    cnpj_ilimitados: true,
                    tipo_consumo: "CREDITOS",
                    status: true,
                    ini_vigencia: moment().format("YYYY-MM-DD"),
                    fim_vigencia: moment().add('5', 'years').format("YYYY-MM-DD")
                }).finally();
            }
            return softwareSaved.data;
        } catch (err) {
            commit("core/setError", {
                titulo: 'Erro ao salvar',
                mensagem: err.data.message
            }, {root: true});
            return null;
        }
    }
};

// mutations
const mutations = {
    setSoftwarehouses(state, softwarehouses) {
        state.softwarehouses = softwarehouses;
    },
    setTotalSoftwarehouses(state, totalSoftwarehouses) {
        state.totalSoftwarehouses = totalSoftwarehouses;
    },
    setSoftwarehouseEdicao(state, softwarehouseEdicao) {
        state.softwarehouseEdicao = softwarehouseEdicao;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};