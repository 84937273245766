import apiRequest from "@/util/apiRequest";

export default {
    postLogin(login, password) {
        return new Promise((resolve, reject) => {
            apiRequest.post('auth/login', {
                login: login,
                password: password
            }).then((response) => {
                resolve(response.data.data);
            }).catch((error) => {
                reject(error);
            });
        });
    },
    getLogout() {
        return new Promise((resolve, reject) => {
            apiRequest.get('auth/logout').then((response) => {
                resolve(response.data);
            }).catch((error) => {
                reject(error);
            });
        });
    }
};