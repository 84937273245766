<template>
  <div>
    <v-form ref="form" v-model="isValidForm">

      <v-col cols="12">
        <v-row>
          <v-col cols="6" class="py-1">
            <v-text-field label="Nome" hint="Campo obrigatório"
                          v-model="usuario.nome"
                          :rules="[() => !!usuario.nome || 'Preenchimento obrigatório']"
                          persistent-hint outlined dense prepend-inner-icon="mdi-account"
                          counter maxlength="60"/>
          </v-col>
          <v-col cols="4" class="py-1">
            <v-text-field label="CPF" hint="Campo obrigatório"
                          v-mask="'###.###.###-##'"
                          v-model="usuario.cpf" :rules="[() => !!usuario.cpf || 'Preenchimento obrigatório']"
                          persistent-hint outlined dense prepend-inner-icon="mdi-account-details"
                          counter maxlength="60"/>
          </v-col>
          <v-col cols="2" class="py-1">
            <v-checkbox class="my-1" v-model="usuario.status" label="Ativo"/>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="6" class="py-1">
            <v-text-field label="Email" hint="Campo obrigatório"
                          v-model="usuario.email" :rules="[() => !!usuario.email || 'Preenchimento obrigatório']"
                          persistent-hint outlined dense prepend-inner-icon="mdi-at"
                          counter maxlength="60"/>
          </v-col>
          <v-col cols="2" class="py-1">
            <v-text-field label="DDD" v-model="usuario.telefone.ddd" outlined dense counter maxlength="2"/>
          </v-col>
          <v-col cols="4" class="py-1">
            <v-text-field label="Número" v-model="usuario.telefone.numero" outlined dense counter maxlength="10"
                          prepend-inner-icon="mdi-phone"/>
          </v-col>
        </v-row>
      </v-col>

      <v-col>
        <h3 class="text-uppercase">Dados de acesso</h3>
        <v-col cols="12" class="mb-2">
          <v-divider></v-divider>
        </v-col>

        <v-row>
          <v-col cols="4" class="py-1">
            <v-text-field label="Login" hint="Campo obrigatório"
                          v-model="usuario.login"
                          :rules="[() => !!usuario.login || 'Preenchimento obrigatório']"
                          persistent-hint outlined dense prepend-inner-icon="mdi-account"
                          counter maxlength="60"/>
          </v-col>
          <v-col cols="4" class="py-1">
            <v-text-field label="Senha" :hint="isEdit ? '' : 'Campo obrigatório'"
                          v-model="usuario.password"
                          :persistent-hint="!isEdit" outlined dense
                          :append-icon="passwordShow ? 'mdi-eye' : 'mdi-eye-off'"
                          :type="passwordShow ? 'text' : 'password'"
                          @click:append="passwordShow = !passwordShow"
                          counter maxlength="60"/>
          </v-col>
          <v-col cols="4" class="py-1">
            <v-autocomplete
                hint="Campo obrigatório" persistent-hint
                :search-input.sync="regraAutoCompleteText"
                :items="regraAutoCompleteList"
                :loading="regraAutoCompleteLoading"
                :value="regraSelected.id"
                v-model="regraSelected"
                :rules="[() => !!usuario.role || 'Preenchimento obrigatório']"
                item-value="id"
                item-text="display_name"
                outlined dense
                label="Regra"
                no-data-text="Nenhuma regra encontrada"
                cache-items
                return-object />
          </v-col>
        </v-row>
      </v-col>


      <v-col cols="12 mt-5">
        <v-btn class="mr-5" color="secondary" elevation="2" @click="salvar" :loading="salvarLoading">Salvar</v-btn>
        <v-btn color="accent" outlined :disabled="salvarLoading" @click="cancelar">Cancelar</v-btn>
      </v-col>
    </v-form>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import _ from "lodash";
import UsuarioEntitie from '@/entities/usuarioEntitie';
import regraApi from "@/api/regras";

export default {
  name: "Edicao",
  data: () => ({
    passwordShow: false,
    isValidForm: true,
    salvarLoading: false,
    isEdit: false,
    usuario: {...UsuarioEntitie},
    regraAutoCompleteText: '',
    regraAutoCompleteLoading: false,
    regraAutoCompleteList: [],
    regraSelected: {
      id: '',
      display_name: ''
    },
  }),
  computed: {
    ...mapGetters({
      usuarioEdicao: "usuarios/getUsuarioEdicao"
    })
  },
  watch: {
    regraAutoCompleteText: function (value) {
      if (this.regraAutoCompleteLoading) {
        return;
      }

      if (value && value.length >= 3) {
        this.regraAutoCompleteLoading = true;
        this.buscarRegras(value);
      }
    },
    regraSelected: function (regra) {
      this.usuario.role = regra.id
    },
  },
  methods: {
    salvar() {
      this.$refs['form'].validate();
      if (this.isValidForm) {
        this.salvarLoading = true;
        this.$store.dispatch('usuarios/salvar', this.usuario).then((data) => {
          if (data) {
            const isUpdate = this.usuario.id !== null;
            this.$store.commit('core/setSuccess', isUpdate ? 'Atualizado com sucesso!' : 'Criado com sucesso!');
            this.$emit('onUpdate');
            this.cancelar();
          }
        }).finally(() => {
          this.salvarLoading = false;
        });
      }
    },
    cancelar() {
      this.$router.push({hash: ''})
    },
    buscarRegras(regra) {
      regraApi.getTodas({filtro: {displayName: regra}}).then((res) => {
        this.regraAutoCompleteList = res.data;
      }).finally(() => {
        this.regraAutoCompleteLoading = false;
      });
    }
  },
  mounted() {
    if (this.usuarioEdicao) {
      this.isEdit = true;
      this.usuario = _.cloneDeep(this.usuarioEdicao);

      this.regraSelected = {
        id: this.usuario.role.id,
        display_name: this.usuario.role.display_name
      };
      this.regraAutoCompleteText = this.usuario.role.display_name
    }
  }
}
</script>

<style scoped>

</style>