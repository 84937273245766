import cidades from '@/api/cidades';

// initial state
const state = () => ({
    totalCidadesHomologadas: 0,
    cidadesHomologadas: [],
});

// getters
const getters = {
    getTotalCidadesHomologadas(state) {
        return state.totalCidadesHomologadas;
    },
    getCidadesHomologadas(state) {
        return state.cidadesHomologadas;
    }
};

// actions
const actions = {
    buscarTodasCidadesHomologadas({commit}, payload) {
        return cidades.getTodasHomologadas(payload).then((result) => {
            commit('setCidadesHomologadas', result.data);
            commit('setTotalCidadesHomologadas', result.meta.total);
        }).catch(() => {
            commit('setCidadesHomologadas', []);
            commit('setTotalCidadesHomologadas', 0);
        });
    }
};

// mutations
const mutations = {
    setTotalCidadesHomologadas(state, value) {
        state.totalCidadesHomologadas = value;
    },
    setCidadesHomologadas(state, value) {
        state.cidadesHomologadas = value;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};