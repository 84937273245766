<template>
  <div>
    <v-row>
      <v-col cols="12" class="py-0">
        <ListagemOpcoes :filtrados="totalFiltrado" :temFiltro="true" :filtroAberto="true">
          <v-row>
            <v-col cols="3" class="py-1">
              <v-autocomplete
                  :search-input.sync="empresaAutoCompleteText"
                  :items="empresaAutoCompleteList"
                  :loading="empresaAutoCompleteLoading"
                  v-model="filtro.consumerId"
                  :item-text="nomeAutoComplete"
                  item-value="consumer_id"
                  outlined dense
                  label="Empresas"
                  no-data-text="Nenhuma empresa encontrada"
                  cache-items>
              </v-autocomplete>
            </v-col>
            <v-col cols="3" class="py-1">
              <v-text-field label="Nome cliente" v-model="filtro.nomeCliente" outlined dense/>
            </v-col>
            <v-col cols="3" class="py-1">
              <v-select
                  :items="[{ name: 'Produção', id: 1 },{ name: 'Homologação', id: 0 },]"
                  v-model.trim="filtro.ambiente"
                  item-value="id"
                  item-text="name"
                  clearable
                  dense
                  outlined
                  label="Ambiente prefeitura"
                  name="ambiente"
              ></v-select>
            </v-col>
            <v-col cols="3" class="py-1">
              <v-select
                  :items="[
                      { name: 'Emitida', id: 1 },
                      { name: 'Cancelada', id: 2 },
                      { name: 'Processando', id: 3 },
                      { name: 'Aguardando prefeitura', id: 8 },
                      { name: 'Rejeitada', id: 4 },
                      { name: 'Erro interno', id: 5 },
                      ]"
                  v-model.trim="filtro.status"
                  item-value="id"
                  item-text="name"
                  dense
                  clearable
                  outlined
                  label="Status"
                  name="status"
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="py-0 mb-2">
              <v-btn class="mr-5" color="primary" elevation="2" small :loading="loading" @click="filtrarDados(false)">
                Filtrar
              </v-btn>
              <v-btn color="default" elevation="2" outlined small :disabled="loading" @click="filtrarDados(true)">
                Limpar
              </v-btn>
            </v-col>
          </v-row>
        </ListagemOpcoes>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-data-table
            :headers="headers"
            :items="documentos"
            :options.sync="options"
            :server-items-length="totalDocumentos"
            :loading="loading"
            :footer-props="{
              'items-per-page-options': [5, 10, 15, 30, 50]
            }"
            :items-per-page="15"
            item-key="id"
            locale="pt-BR"
            class="elevation-1">
          <v-progress-linear slot="progress" color="secondary" indeterminate
                             class="v-progress-linear--absolute"></v-progress-linear>
          <template v-slot:item="{ item }">
            <tr>
              <td>{{ item.id }}</td>
              <td>{{ item.numeracao_rps }}</td>
              <td>{{ item.nota_numero !== null ? item.nota_numero : '0' }}</td>
              <td>{{ formatarData(item.updated_at) }}</td>
              <td style="max-width: 70px;">
                <v-tooltip bottom transition="fade-transition">
                  <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs"
                         v-on="on" class="text-no-wrap text-truncate">{{ item.nome_cliente }}
                    </div>
                  </template>
                  <label>{{ item.nome_cliente }}</label>
                </v-tooltip>
              </td>
              <td>
                <money readonly v-model="item.valor_total_liquido" v-bind="money"></money>
              </td>
              <td>
                <v-chip :color="notaStatus[item.nota_status].color" small :ripple="false">
                  {{ notaStatus[item.nota_status].text }}
                </v-chip>
              </td>
              <td>
                <v-menu offset-y transition="slide-y-transition">
                  <template v-slot:activator="{ attrs, on }">
                    <v-btn color="primary" icon small class="white--text" v-bind="attrs" v-on="on">
                      <v-icon>
                        mdi-arrow-down-drop-circle-outline
                      </v-icon>
                    </v-btn>
                  </template>

                  <v-list>
                    <v-list-item link dense v-if="item.nota_key" :href="item.nota_key" target="_blank">
                      <v-list-item-title>
                        <v-icon small color="primary">mdi-file-document-outline</v-icon>
                        Baixar danfe
                      </v-list-item-title>
                    </v-list-item>

                    <v-list-item link dense v-if="item.nota_xml" :href="item.nota_xml" target="_blank">
                      <v-list-item-title>
                        <v-icon small color="primary">mdi-xml</v-icon>
                        Baixar XML
                      </v-list-item-title>
                    </v-list-item>

                    <v-list-item link dense v-if="item.nota_xml_envio" :href="item.nota_xml_envio" target="_blank">
                      <v-list-item-title>
                        <v-icon small color="primary">mdi-xml</v-icon>
                        Baixar XML de envio
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {totalFiltrado} from '@/util/util'
import moment from "moment";
import _ from 'lodash';
import empresaApi from '@/api/empresa';
import ListagemOpcoes from "@/components/generic/ListagemOpcoes";

moment.locale("pt-br");

export default {
  name: "Listagem",
  components: {ListagemOpcoes},
  props: ['refresh'],
  data: () => ({
    loading: false,
    options: {
      page: 1,
      itemsPerPage: 15
    },
    filtro: {
      consumerId: null,
      nomeCliente: null,
      ambiente: null,
      status: null
    },
    totalFiltrado: null,
    empresaAutoCompleteText: '',
    empresaAutoCompleteLoading: false,
    empresaAutoCompleteList: [],
    empresaSelected: {
      nome_razao_social: '',
      consumer_id: ''
    },
    headers: [
      {
        text: "#",
        value: "id",
        sortable: false,
        align: "left",
      },
      {
        text: "RPS",
        value: "numeracao_rps",
        sortable: false,
        align: "left",
      },
      {
        text: "Nº",
        value: "nota_numero",
        sortable: false,
        width: '5%'
      },
      {
        text: "Data",
        value: "updated_at",
        sortable: false,
        width: '5%'
      },
      {
        text: "Cliente",
        value: "nome_cliente",
        sortable: false,
        width: '70%'
      },
      {
        text: "Valor",
        value: "valor_total_liquido",
        sortable: false,
        width: '5%'
      },
      {
        text: "Situação",
        value: "nota_status",
        sortable: false,
        width: '5%'
      },
      {
        text: "Ações",
        value: "acoes",
        sortable: false,
        align: "right",
        width: '5%'
      }
    ],
    money: {
      decimal: ',',
      thousands: '.',
      prefix: 'R$ ',
      suffix: '',
      precision: 2,
      masked: false
    },
    notaStatus: {
      0: {
        text: 'Cadastrada',
        color: 'primary'
      },
      1: {
        text: 'Emitida',
        color: 'secondary'
      },
      2: {
        text: 'Cancelada',
        color: 'error'
      },
      3: {
        text: 'Processando',
        color: 'primary'
      },
      4: {
        text: 'Rejeitada',
        color: '#e0e0e0'
      },
      5: {
        text: 'Erro',
        color: '#e0e0e0'
      },
      6: {
        text: 'Cancelando',
        color: 'warning'
      },
      7: {
        text: 'Consultando',
        color: 'warning'
      },
      8: {
        text: 'Consultando',
        color: 'warning'
      },
    }
  }),
  computed: {
    ...mapGetters({
      documentos: "nfse/getNfses",
      totalDocumentos: "nfse/getTotalNfses"
    })
  },
  watch: {
    refresh: function () {
      this.buscarDados();
    },
    options: {
      handler() {
        this.buscarDados();
      },
      deep: false
    },
    empresaAutoCompleteText: function (value) {
      if (this.empresaAutoCompleteLoading) {
        return;
      }

      if (value && value.length >= 1) {
        this.empresaAutoCompleteLoading = true;
        this.buscarEmpresa(value);
      }
    },
    empresaSelected: function (empresa) {
      this.filtro.consumerId = empresa.consumer_id
    },
  },
  methods: {
    nomeAutoComplete(item) {
      return `${item.id} - ${item.nome_razao_social}`;
    },
    buscarDados() {
      this.loading = true;
      const filtragem = _.cloneDeep(this.options);
      filtragem.filtro = _.cloneDeep(this.filtro);
      this.$store.dispatch("nfse/buscarTodasNfses", filtragem).finally(() => this.loading = false);
    },
    filtrarDados(clear) {
      this.totalFiltrado = totalFiltrado(this.filtro);
      if (clear) {
        this.filtro = {
          consumerId: null,
          nomeCliente: null,
          ambiente: null,
          status: null
        }
        if (this.totalFiltrado !== null) {
          this.totalFiltrado = null;
        }
      }

      this.buscarDados();
    },
    buscarEmpresa(empresa) {
      const filtro = {};
      const reg = new RegExp('^[0-9]+$');
      if (reg.test(empresa)) {
        filtro.cnpjCpf = empresa;
      } else {
        filtro.nomeRazaoSocial = empresa;
      }

      empresaApi.getTodas({filtro: filtro}).then((res) => {
        this.empresaAutoCompleteList = res.data;
      }).finally(() => {
        this.empresaAutoCompleteLoading = false;
      });
    },
    formatarData(date) {
      return moment(date).format("DD/MM/YYYY");
    }
  }
}
</script>

<style scoped>

</style>