import nfse from '@/api/nfse';

// initial state
const state = () => ({
    totalNfses: 0,
    nfses: [],
    nfseEdicao: null
});

// getters
const getters = {
    getTotalNfses(state) {
        return state.totalNfses;
    },
    getNfses(state) {
        return state.nfses;
    },
    getNfseEdicao(state) {
        return state.nfseEdicao;
    }
};

// actions
const actions = {
    buscarTodasNfses({commit}, payload) {
        return nfse.getTodas(payload).then((result) => {
            commit('setNfses', result.data);
            commit('setTotalNfses', result.meta.total);
        }).catch(() => {
            commit('setNfses', []);
            commit('setTotalNfses', 0);
        });
    }
};

// mutations
const mutations = {
    setTotalNfses(state, value) {
        state.totalNfses = value;
    },
    setNfses(state, value) {
        state.nfses = value;
    },
    setNfseEdicao(state, value) {
        state.nfseEdicao = value;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};