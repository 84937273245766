import apiRequest from "@/util/apiRequest";

export default {
    cadastrar(data) {
        return new Promise((resolve, reject) => {
           apiRequest.post(
                'billing/cadastrar-ou-atualiza',
                data,
                process.env.VUE_APP_BASE_URL_ADMIN).then((response) => {
                resolve(response.data);
            }).catch((error) => {
                reject(error);
            });
        });
    }
};