import apiRequest from "@/util/apiRequest";

export default {
    getBuscarDocumentosFiscais() {
        return new Promise((resolve, reject) => {
            apiRequest.get('relatorios/documentos-fiscais').then((response) => {
                resolve(response.data);
            }).catch((error) => {
                reject(error);
            });
        });
    }
};