<template>
  <div>
    <v-form ref="form" v-model="isValidForm">
      <v-col cols="12" v-if="!certificadoInstalado">
        <v-row>
          <v-col cols="6" class="py-0">
            <v-file-input
                prepend-icon=""
                prepend-inner-icon="mdi-paperclip"
                type="file"
                placeholder="Selecione o certificado!"
                label="Certificado"
                v-model="certificadoAnexo"
                outlined dense></v-file-input>
          </v-col>
          <v-col cols="6" class="py-0">
            <v-text-field
                class="borderInput"
                outlined dense
                label="Senha Certificado"
                name="certificado_senha"
                v-model="certificado.certificado_senha"
                :append-icon="passwordCertificadoShow ? 'mdi-eye' : 'mdi-eye-off'"
                :type="passwordCertificadoShow ? 'text' : 'password'"
                @click:append="passwordCertificadoShow = !passwordCertificadoShow"
                prepend-inner-icon="mdi-key"></v-text-field>
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="12" class="py-0 mb-5" v-else>
        <v-banner single-line>
          <v-icon slot="icon" :color="expirouCertificado ? 'red' : 'success'" size="36">
            mdi-certificate-outline
          </v-icon>
          <b>Você possuí um certificado vinculado que {{expiracaoCertificado}}</b>

          <template v-slot:actions>
            <v-btn color="error" outlined small @click="remover" :loading="salvarLoading">
              Desvincular
            </v-btn>
          </template>
        </v-banner>
      </v-col>

      <v-col cols="12" v-if="!certificadoInstalado">
        <v-btn class="mr-5" color="secondary" elevation="2" @click="salvar" :loading="salvarLoading">Vincular</v-btn>
        <v-btn color="accent" elevation="2" outlined :disabled="salvarLoading" @click="cancelar" v-if="!fromMeusDados">Cancelar</v-btn>
      </v-col>
    </v-form>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import _ from "lodash";
import moment from "moment";
import certificadoApi from '@/api/certificados';
import Validator from "@/util/validation";
import CertificadoEntitie from "@/entities/certificadoEntitie";

export default {
  name: "Edicao",
  props: ['fromMeusDados'],
  data: () => ({
    isValidForm: true,
    salvarLoading: false,
    validations: {...Validator},
    certificado: {... CertificadoEntitie},
    certificadoInstalado: false,
    passwordCertificadoShow: false,
    certificadoAnexo: [],
  }),
  computed: {
    ...mapGetters({
      empresaEdicao: "empresa/getEmpresaEdicao"
    }),
    expirouCertificado() {
      const dataExpira = moment(this.certificado.certificado_expiracao).utc();
      const dataNow = moment().utc();

      return dataNow.isAfter(dataExpira)
    },
    expiracaoCertificado() {
      const dataExpira = moment(this.certificado.certificado_expiracao).utc();
      let pre = 'expira em: ';
      if (this.expirouCertificado) {
        pre = 'expirou em: '
      }
      return pre + dataExpira.format("DD/MM/YYYY");
    }
  },
  watch: {
    certificadoAnexo: function () {
      if (this.certificadoAnexo) {
        const reader = new FileReader();
        reader.readAsDataURL(this.certificadoAnexo)
        reader.onload = () => {
          this.certificado.certificado_base64 = reader.result.toString().replace(/^data:(.*,)?/, '');
        }
      }
    }
  },
  methods: {
    salvar() {
      this.$refs['form'].validate();
      if (this.isValidForm) {
        this.salvarLoading = true;
        certificadoApi.salvar(this.certificado).then((result) => {
          this.$store.commit('core/setSuccess', 'Certificado vinculado');
          this.certificado = result.data;
          this.certificadoInstalado = true;
        }).catch((err) => {
          this.$store.commit("core/setError", {
            titulo: 'Erro ao vincular o certificado da empresa',
            mensagem: err.data.message
          });
        }).finally(() => {
          this.salvarLoading = false;
        });
      }
    },
    remover() {
      this.salvarLoading = true;
      certificadoApi.remover(this.certificado.id).then(() => {
        this.$store.commit('core/setSuccess', 'Certificado desvinculado');
        this.certificado = {... CertificadoEntitie};
        this.certificadoInstalado = false;
      }).catch((err) => {
        this.$store.commit("core/setError", {
          titulo: 'Erro ao desvincular o certificado da empresa',
          mensagem: err.data.message
        });
      }).finally(() => {
        this.salvarLoading = false;
      });
    },
    loadCertificado() {
      if (this.empresaEdicao.certificado_ativo) {
        this.certificado = _.cloneDeep(this.empresaEdicao.certificado_ativo);
        this.certificadoInstalado = true
      } else {
        this.certificadoInstalado = false
      }
    },
    cancelar() {
      this.$router.push({hash: ''});
    }
  },
  mounted() {
    if (this.empresaEdicao) {
      this.loadCertificado();
    }
  }
}
</script>

<style scoped>

</style>