<template>
  <div>
    <v-form ref="form" v-model="isValidForm">
      <v-col cols="12">
        <v-row>
          <v-col cols="6" class="py-1">
            <v-text-field label="Razão Social" hint="Campo obrigatório"
                          persistent-hint outlined dense prepend-inner-icon="mdi-store"
                          v-model="softwarehouse.nome_razao_social"
                          counter maxlength="60"
                          :rules="[validations.required]"/>
          </v-col>
          <v-col cols="4" class="py-1">
            <v-text-field label="Nome Fantasia" counter maxlength="60" outlined dense v-model="softwarehouse.nome_fantasia"/>
          </v-col>

          <v-col cols="2" class="py-1">
            <v-checkbox label="Cadastrar em Staging" outlined dense v-model="softwarehouse.cadastrarStaging"
            ></v-checkbox>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6" class="py-1">
            <v-text-field label="CPF/CNPJ" hint="Campo obrigatório" persistent-hint outlined dense
                          v-model="softwarehouse.cnpj_cpf" counter maxlength="18"
                          v-mask="['###.###.###-##','##.###.###/####-##']"
                          :rules="[validations.required]"/>
          </v-col>
          <v-col cols="6" class="py-1">
            <v-text-field label="Host" outlined dense prepend-inner-icon="mdi-cloud" counter maxlength="100"
                          v-model="softwarehouse.host"/>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4" class="py-1">
            <v-text-field label="Responsável" outlined dense counter maxlength="255" v-model="softwarehouse.responsavel"/>
          </v-col>
          <v-col cols="3" class="py-1">
            <v-text-field label="Email" hint="Campo obrigatório" persistent-hint outlined dense
                          v-model="softwarehouse.email" counter maxlength="400"
                          :rules="[validations.required]"/>
          </v-col>
          <v-col cols="2" class="py-1">
            <v-text-field label="DDD" hint="Campo obrigatório" persistent-hint outlined dense
                          v-model="softwarehouse.telefone_principal.ddd" counter maxlength="2"
                          :rules="[validations.required]"/>
          </v-col>
          <v-col cols="3" class="py-1">
            <v-text-field label="Número" hint="Campo obrigatório" persistent-hint outlined dense prepend-inner-icon="mdi-phone"
                          v-model="softwarehouse.telefone_principal.numero" counter maxlength="10"
                          :rules="[validations.required]"/>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="3" class="py-1">
            <v-text-field label="Inscrição Estadual" outlined dense counter maxlength="45"
                          v-model="softwarehouse.insc_estadual"/>
          </v-col>
          <v-col cols="3" class="py-1">
            <v-text-field label="Inscrição Municial" outlined dense counter maxlength="45"
                          v-model="softwarehouse.insc_municipal"/>
          </v-col>
          <v-col cols="3" class="py-1">
            <v-text-field label="Cnae Primário" outlined dense counter maxlength="7" v-model="softwarehouse.cnae_primario"/>
          </v-col>
          <v-col cols="3" class="py-1">
            <v-text-field label="ID Empresa Parceiro" outlined dense v-model="softwarehouse.id_empresa_parceiro"/>
          </v-col>
        </v-row>
      </v-col>

      <Endereco :endereco="softwarehouse.endereco_principal" v-if="softwarehouse.endereco_principal"></Endereco>

      <v-col cols="12" v-if="softwarehouse.usuario_administrador">
        <h3 class="text-uppercase">Usuário administrador</h3>
        <v-col cols="12" class="mb-2">
          <v-divider></v-divider>
        </v-col>

        <v-row>
          <v-col cols="6" class="py-1">
            <v-text-field label="Nome" hint="Campo obrigatório" persistent-hint outlined dense
                          v-model="softwarehouse.usuario_administrador.nome" counter maxlength="60"
                          :rules="[validations.required]"/>
          </v-col>
          <v-col cols="6" class="py-1">
            <v-text-field label="CPF" hint="Campo obrigatório" persistent-hint outlined dense append-icon="mdi-refresh"
                          v-model="softwarehouse.usuario_administrador.cpf" counter maxlength="15"
                          @click:append="gerarCpf"
                          v-mask="'###.###.###-##'"
                          :rules="[validations.required]"/>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="7" class="py-1">
            <v-text-field label="Email" hint="Campo obrigatório" persistent-hint outlined dense
                          v-model="softwarehouse.usuario_administrador.email" counter maxlength="255"
                          :rules="[validations.required]"/>
          </v-col>
          <v-col cols="2" class="py-1">
            <v-text-field label="DDD" outlined dense counter maxlength="2"
                          v-model="softwarehouse.usuario_administrador.telefone.ddd"/>
          </v-col>
          <v-col cols="3" class="py-1">
            <v-text-field label="Número" outlined dense counter maxlength="10" prepend-inner-icon="mdi-phone"
                          v-model="softwarehouse.usuario_administrador.telefone.numero"/>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="6" class="py-1">
            <v-text-field label="Login" hint="Campo obrigatório" persistent-hint outlined dense
                          v-model="softwarehouse.usuario_administrador.login" counter maxlength="45"
                          :rules="[validations.required]"/>
          </v-col>
          <v-col cols="6" class="py-1">
            <v-text-field label="Senha" hint="Campo obrigatório" persistent-hint outlined dense append-icon="mdi-refresh"
                          v-model="softwarehouse.usuario_administrador.password" @click:append="gerarSenha"
                          :rules="[validations.required]"/>
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="12 mt-5">
        <v-btn class="mr-5" color="secondary" elevation="2" @click="salvar" :loading="salvarLoading">Salvar</v-btn>
        <v-btn color="accent" elevation="2" outlined :disabled="salvarLoading" @click="cancelar">Cancelar</v-btn>
      </v-col>
    </v-form>
  </div>
</template>

<script>
import Endereco from "@/components/generic/Endereco";
import {gerarCpf, gerarSenha} from "@/util/util";
import SoftwarehouseEntitie from "@/entities/softwarehouseEntitie";
import Validator from "@/util/validation";
import {mapGetters} from "vuex";
import _ from "lodash";

export default {
  name: "Edicao",
  components: {Endereco},
  data: () => ({
    isValidForm: true,
    salvarLoading: false,
    softwarehouse: {... SoftwarehouseEntitie},
    validations: {...Validator}
  }),
  computed: {
    ...mapGetters({
      softwarehouseEdicao: "softwarehouse/getSoftwarehouseEdicao"
    })
  },
  methods: {
    gerarCpf() {
      this.softwarehouse.usuario_administrador.cpf = gerarCpf();
    },
    gerarSenha() {
      this.softwarehouse.usuario_administrador.password = gerarSenha();
    },
    salvar() {
      this.$refs['form'].validate();
      if (this.isValidForm) {
        this.salvarLoading = true;
        this.$store.dispatch('softwarehouse/salvar', this.softwarehouse).then((data) => {
          if (data) {
            const isUpdate = this.softwarehouse.id !== null;
            this.$store.commit('core/setSuccess', isUpdate ? 'Atualizado com sucesso!' : 'Criado com sucesso!')
            this.$emit('onUpdate');
            this.cancelar();
          }
          this.salvarLoading = false;
        });
      }
    },
    cancelar() {
      this.softwarehouseEditar = null;
      this.$router.push({hash: ''});
    },
  },
  mounted() {
    if (this.softwarehouseEdicao) {
      this.softwarehouse = _.cloneDeep(this.softwarehouseEdicao);
    }
  }
}
</script>

<style scoped>

</style>