import {defineAbility} from '@casl/ability';

const rolesDefault = [
    'super_administrador',
    'softhouse_administrador'
];

const permissions = [
    'certificado',
    'cliente',
    'empresa',
    'empresa_config',
    'nfs',
    'nf',
    'inutilizacao',
    'nfs_params',
    'nfs_servico_params',
    'roles',
    'usuario',
    'softhouse',
    'suporte_chamado',
    'suporte_chamado_mensagem',
    'cidades_homologadas'
];

const permissionsMap = [
    'criar',
    'ler',
    'atualizar',
    'deletar',
    'emitir',
    'cancelar'
];

export default defineAbility((can, cannot) => {
    permissions.forEach((permission) => {
        cannot(permissionsMap, permission);
    });
    rolesDefault.forEach((role) => {
        cannot('all', role);
    });
});
