import apiRequest from "@/util/apiRequest";
import _ from "lodash";

export default {
    getTodas(dadosBusca) {
        return new Promise((resolve, reject) => {
            const filtragem = {};
            if (dadosBusca.page !== undefined) {
                filtragem.page = dadosBusca.page;
            }
            if (dadosBusca.itemsPerPage !== undefined) {
                filtragem.perPage = dadosBusca.itemsPerPage;
            }

            let link = 'softhouse/todos';
            if (!_.isEmpty(filtragem)) {
                const params = new URLSearchParams(filtragem);
                link += '?' + params.toString();
            }

            apiRequest.get(link, process.env.VUE_APP_BASE_URL_ADMIN).then((response) => {
                resolve(response.data);
            }).catch((error) => {
                reject(error);
            });
        });
    },
    salvar(data) {
        return new Promise((resolve, reject) => {
            const isUpdate = data.id !== null;
            let link = 'softhouse/salvar';
            if (isUpdate) {
                delete data.usuario_administrador;
                link = 'softhouse/atualizar/' + data.id;
            }

            if (isUpdate) {
                apiRequest.put(
                    link,
                    data,
                    process.env.VUE_APP_BASE_URL_ADMIN).then((response) => {
                    resolve(response.data);
                }).catch((error) => {
                    reject(error);
                });
            } else {
                apiRequest.post(
                    link,
                    data,
                    process.env.VUE_APP_BASE_URL_ADMIN).then((response) => {
                    resolve(response.data);
                }).catch((error) => {
                    reject(error);
                });
            }
        });
    }
};