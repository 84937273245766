import solicitacaoHomologacao from '@/api/solicitacaoHomologacao';

// initial state
const state = () => ({
    totalSolicitacaoHomologacoes: 0,
    solicitacaoHomologacoes: [],
    solicitacaoHomologacaoEdicao: null,
});

// getters
const getters = {
    getTotalSolicitacaoHomologacoes(state) {
        return state.totalSolicitacaoHomologacoes;
    },
    getSolicitacaoHomologacoes(state) {
        return state.solicitacaoHomologacoes;
    },
    getSolicitacaoHomologacaoEdicao(state) {
        return state.solicitacaoHomologacaoEdicao;
    }
};

// actions
const actions = {
    async buscarTodosHomologacoes({commit}, payload) {
        return solicitacaoHomologacao.getTodasSolicitacoes(payload).then((result) => {
            commit('setSolicitacaoHomologacoes', result.data);
            commit('setTotalSolicitacaoHomologacoes', result.meta.total);
        }).catch(() => {
            commit('setSolicitacaoHomologacoes', []);
            commit('setTotalSolicitacaoHomologacoes', 0);
        });
    },
    async salvar({commit}, payload) {
        return solicitacaoHomologacao.salvar(payload).catch((err) => {
            commit("core/setError", {
                titulo: 'Erro abrir a solicitação',
                mensagem: err.data.message
            }, {root: true});
        });
    },
    async encerrarChamado({commit}, payload) {
        return solicitacaoHomologacao.encerrarSolicitacao(payload.idSolicitacaoHomologacao).catch((err) => {
            commit("core/setError", {
                titulo: 'Erro ao buscar as mensagens',
                mensagem: err.data.message
            }, {root: true});
        });
    },
};

// mutations
const mutations = {
    setTotalSolicitacaoHomologacoes(state, value) {
        state.totalSolicitacaoHomologacoes = value;
    },
    setSolicitacaoHomologacoes(state, value) {
        state.solicitacaoHomologacoes = value;
    },
    setSolicitacaoHomologacaoEdicao(state, value) {
        state.solicitacaoHomologacaoEdicao = value;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};