<template class="template_login">
  <v-app>
    <v-main>
      <v-container fluid class="pt-0">

        <img src="@/assets/svg/banner.svg" class="banner" alt="banner"/>

        <div class="login-box">
          <v-card class="cardLogin" elevation="0">
            <v-row align="center" justify="center">
              <v-col cols="12" class="text-center"><img src="@/assets/svg/logoNotaSemBy.svg" class="logo-login"
                                                        alt="logo"/></v-col>
            </v-row>
            <v-row class="ma-0">
              <v-col cols="12" class="pa-30">
                <div class="text-body-2 pa-5 account" hidden><label>Não tem uma conta?</label>
                  <v-btn color="#3275A6" outlined large dark>Criar</v-btn>
                </div>
                <v-form id="formLogin" v-model="formValid" @submit.prevent="login()" class="pa-5 pt-0"><h1
                    class="tittleLogin"><span>Olá!</span> Acesse sua conta </h1>
                  <v-card-text class="pa-0"><span class="titleInput">USUÁRIO</span>
                    <v-text-field class="borderInput" outlined id="inputLogin" v-model.trim="usuario.login" name="login"
                                  type="text" :rules="rules"></v-text-field>
                    <span class="titleInput">SENHA<img src="@/assets/svg/help.svg"/></span>
                    <v-text-field class="borderInput" outlined id="inputSenha" v-model.trim="usuario.password"
                                  name="senha" type="password" :rules="rules"></v-text-field>
                  </v-card-text>
                  <v-card-actions>
                    <v-row class="btnAccessAcount">
                      <v-btn id="btnAccess" block color="#148DEB" :loading="loading" type="submit" depressed
                             :disabled="!formValid" class="fc-white py-5">Acesse sua conta
                      </v-btn>
                    </v-row>
                  </v-card-actions>
                  <v-row class="forgotPass"><a>Esqueci minha senha</a></v-row>
                  <span class="spanColor"></span></v-form>
              </v-col>
            </v-row>
          </v-card>

          <div class="info-baixo">
            <h5 class="info-doc-label">ACESSE <br/>
              <a href="https://docs.notafacil.io/">
                <img src="@/assets/svg/acesse.svg" width="20" alt="acesse"/>
                <span>Documentação</span>
              </a>
            </h5>
            <h5 class="info-powered">
              powered by <img src="@/assets/svg/vhsys_logo.svg" alt="vhsys"/>
            </h5>
          </div>
        </div>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>

import {AbilityBuilder} from "@casl/ability";

export default {
  name: "Login",
  data: () => ({
    usuario: {
      login: "",
      password: "",
    },
    formValid: false,
    loading: false,
    button: true,
    passwordVisibility: false,
    authData: null,
    rules: [(text) => !!text || "Preenchimento Obrigatório"],
  }),
  methods: {
    login() {
      this.loading = true;
      this.$store
          .dispatch("authentication/login", this.usuario)
          .then((result) => {
            this.authData = result;
            this.loading = false;
            this.updateAbility();
            this.$router.push("/");
          })
          .catch(() => {
            this.loading = false;
            this.usuario.password = '';
          });
    },
    updateAbility() {
      if (this.authData) {
        const permissoes = this.authData.usuario.permissoes;
        const regras = this.authData.usuario.regras;

        const { can, rules } = new AbilityBuilder();

        permissoes.forEach((permissao) => {
          const perm = permissao.split('-');
          can(perm[1], perm[0]);
        });
        regras.forEach((regra) => {
          can('all', regra);
        });

        this.$ability.update(rules);
      }
    }
  },
};
</script>

<style scoped lang="scss">

.template_login {
  background-color: #e8f5ff;

  .v-container {
    position: relative;
    text-align: -webkit-center
  }
}

.banner {
  position: absolute;
  right: 0;
  margin: 24px 0 24px 45px;
  border-radius: 50px 0 0 50px;
}

.login-box {
  position: absolute;
  left: 25%;
  margin-top: 7%
}

.info-baixo {
  width: 432px;
  position: relative;
  z-index: 2;
  margin-top: 46px;

  h5 {
    font-size: 12px;
    font-style: normal;
    line-height: 15px;
    letter-spacing: 0;
    text-align: left;
  }

  .info-doc-label {
    font-weight: 500;
    margin-bottom: 47%;

    a {
      text-decoration: none;
      color: #0e2a3e !important;

      img {
        margin: 10px 10px 0 10px;
      }

      span {
        vertical-align: super;
        font-weight: 600;
      }
    }
  }

  .info-powered {
    font-weight: 600;

    img {
      vertical-align: middle;
      margin-left: 10px;

    }
  }
}


.v-text-field :last-child :first-child {
  min-height: auto;
  max-height: 36px !important;
}

.v-text-field :last-child :first-child .v-text-field__slot {
  min-height: auto;
  max-height: 36px !important;
}


.spanColor {
  position: absolute;
  width: 83%;
  bottom: 0px;
  height: 2px;
  padding: 0px 41px;
  background: linear-gradient(
          90deg,
          rgb(255, 255, 255) 0%,
          rgb(50, 158, 240) 26.15%,
          rgb(107, 200, 61) 77.71%,
          rgb(255, 255, 255) 100%
  );
}

.logo-login {
  width: 213px;
  height: 33px;
  margin-top: 25px;
}

.borderInput {
  border-radius: 4px !important;

  :first-child :first-child {
    border-color: #cad0d5 !important;
  }
}

.account {
  text-align-last: end;
  color: #3275a6;
  max-width: 100%;
  min-width: 100%;

  label {
    margin: 13px;
  }
}

.tittleLogin {
  color: #0e2a3e 100%;
  font-size: 20px;
  font-weight: 400;
  line-height: 32px;
  margin: 15px 0px 25px 0px;

  span {
    font-weight: 700;
    color: #1979c2;
  }
}

.titleInput {
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
  color: #0e2a3e;

  img {
    vertical-align: middle;
    margin-bottom: 4px;
    font-size: 15px;
  }
}

.btnAccessAcount {
  v-btn {
    height: 48px;
    width: 349px;
    border-radius: 12px;

    .v-btn--disabled {
      background-color: #e8f5ff !important;
      color: #8899a5 !important;
    }

    .v-btn--block {
      background-color: #e8f5ff !important;
      color: #8899a5 !important;
    }

    .v-btn--depressed {
      background-color: #e8f5ff !important;
      color: #8899a5 !important;
    }
  }
}

.forgotPass {
  justify-content: center;
  margin: 10px;

  a {
    color: #0e2a3e;
    font-weight: 600;
    font-size: 12px;
    margin: 10px 5px 0px 0px;
  }
}


.cardLogin {
  border-radius: 20px !important;
  text-align: justify;
  width: 432px;
}
</style>
