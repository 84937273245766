import apiRequest from "@/util/apiRequest";


const url = 'https://viacep.com.br/ws/';
export default {
    buscarCep(cep) {
        return new Promise((resolve, reject) => {
            const options = {
                method: "GET",
                mode: "cors"
            };
            fetch(url + cep + '/json', options).then((response) => {
                resolve(response.json());
            }).catch((error) => {
                reject(error);
            });
        });
    },
    buscarCidade(nome) {
        return new Promise((resolve, reject) => {
            apiRequest.get('cidade?nome='+nome).then((response) => {
                resolve(response.data);
            }).catch((error) => {
                reject(error);
            });
        });
    }
};