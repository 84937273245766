import apiRequest from "@/util/apiRequest";
import _ from "lodash";

export default {
    getTodas(dadosBusca) {
        return new Promise((resolve, reject) => {
            const filtragem = {};
            if (dadosBusca.page !== undefined) {
                filtragem.page = dadosBusca.page;
            }
            if (dadosBusca.itemsPerPage !== undefined) {
                filtragem.perPage = dadosBusca.itemsPerPage;
            }
            const filtro = dadosBusca.filtro;
            if (filtro) {
                if (!_.isEmpty(filtro.displayName)) {
                    filtragem.display_name = filtro.displayName;
                }
            }

            let link = 'usuario/regras/todos';
            if (!_.isEmpty(filtragem)) {
                const params = new URLSearchParams(filtragem);
                link += '?' + params.toString();
            }

            apiRequest.get(link).then((response) => {
                resolve(response.data);
            }).catch((error) => {
                reject(error);
            });
        });
    },
};