<template>
  <div>
    <v-row>
      <v-col cols="12" class="py-0">
        <ListagemOpcoes :filtrados="totalFiltrado" :temFiltro="true" :adicionarNovo="true">
          <v-row>
            <v-col cols="4" class="py-1">
              <v-select
                  :items="['Em aberto', 'Em andamento', 'Aguardando resposta', 'Encerrado', 'Rejeitado']"
                  v-model.trim="filtro.statusSolicitacao"
                  dense
                  outlined
                  label="Situação"
                  name="statusSolicitacao"
              ></v-select>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" class="py-0 mb-2">
              <v-btn class="mr-5" color="primary" elevation="2" small :loading="loading" @click="filtrarDados(false)">
                Filtrar
              </v-btn>
              <v-btn color="default" elevation="2" outlined small :disabled="loading" @click="filtrarDados(true)">
                Limpar
              </v-btn>
            </v-col>
          </v-row>
        </ListagemOpcoes>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-data-table
            style="cursor: pointer"
            :headers="headers"
            :items="items"
            :options.sync="options"
            :server-items-length="totalItems"
            :loading="loading"
            :footer-props="{
              'items-per-page-options': [5, 10, 15, 30, 50]
            }"
            :items-per-page="15"
            @click:row="rowClick"
            item-key="id"
            locale="pt-BR"
            class="elevation-1">
          <v-progress-linear slot="progress" color="secondary" indeterminate class="v-progress-linear--absolute"></v-progress-linear>
          <template v-slot:item="{ item }">
            <tr @click="rowClick(item)">
              <td>{{ item.id }}</td>
              <td>{{ item.cidade.nome }}/{{ item.cidade.estado.sigla }}</td>
              <td>{{ formatarData(item.data_abertura) }}</td>
              <td>
                <v-chip :color="prioridade[item.prioridade].color" small :ripple="false">
                  {{ prioridade[item.prioridade].text }}
                </v-chip>
              </td>
              <td align="center">
                <v-chip :color="chamadoStatus[item.status_solicitacao].color" small :ripple="false">
                  {{ chamadoStatus[item.status_solicitacao].text }}
                </v-chip>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import moment from "moment";
import {totalFiltrado} from "@/util/util";
import _ from 'lodash';
import ListagemOpcoes from "@/components/generic/ListagemOpcoes";
import ChamadoEntitie from '@/entities/chamadoEntitie';
moment.locale("pt-br");

export default {
  name: "Listagem",
  components: {ListagemOpcoes},
  props: ['refresh'],
  data: () => ({
    totalFiltrado: 1,
    filtro: {
      statusSolicitacao: 'Em aberto'
    },
    loading: false,
    options: {
      page: 1,
      itemsPerPage: 15
    },
    headers: [
      {
        text: "#",
        value: "id",
        sortable: false,
        align: "left"
      },
      {
        text: "Cidade",
        value: "cidade",
        sortable: false,
        align: "left"
      },
      {
        text: "Data abertura",
        value: "data_abertura",
        sortable: false,
        align: "left"
      },
      {
        text: "Prioridade",
        value: "prioridade",
        sortable: false,
        align: "left"
      },
      {
        text: "Situação",
        value: "status_solicitacao",
        sortable: false,
        align: "center"
      }
    ],
    chamadoStatus: {
      'Em aberto': {
        text: 'Aberto',
        color: 'primary'
      },
      'Em andamento': {
        text: 'Andamento',
        color: 'secondary'
      },
      'Encerrado': {
        text: 'Encerrado',
        color: 'default'
      },
      'Rejeitado': {
        text: 'Rejeitado',
        color: 'error'
      }
    },
    prioridade: {
      0: {
        text: 'Baixa',
        color: 'primary'
      },
      1: {
        text: 'Média',
        color: 'secondary'
      },
      2: {
        text: 'Alta',
        color: 'error'
      },
      3: {
        text: 'Urgente',
        color: 'error'
      }
    }
  }),
  computed: {
    ...mapGetters({
      items: "solicitacaoHomologacao/getSolicitacaoHomologacoes",
      totalItems: "solicitacaoHomologacao/getTotalSolicitacaoHomologacoes"
    })
  },
  watch: {
    refresh: function () {
      this.buscarDados();
    },
    options: {
      handler() {
        this.buscarDados();
      },
      deep: true
    }
  },
  methods: {
    buscarDados() {
      this.loading = true;
      const filtragem = _.cloneDeep(this.options);
      filtragem.filtro = _.cloneDeep(this.filtro);
      this.$store.dispatch("solicitacaoHomologacao/buscarTodosHomologacoes", filtragem).finally(() => this.loading = false);
    },
    filtrarDados(clear) {
      this.totalFiltrado = totalFiltrado(this.filtro);
      if (clear) {
        this.filtro = {
          statusSolicitacao: null
        }
        if (this.totalFiltrado !== null) {
          this.totalFiltrado = null;
        }
      }

      this.buscarDados();

    },
    rowClick(row = {... ChamadoEntitie}) {
      this.$store.commit('solicitacaoHomologacao/setSolicitacaoHomologacaoEdicao', row);
      this.$router.push({hash: '#editar'})
    },
    formatarData(date) {
      return moment(date).format("DD/MM/YYYY HH:mm");
    }
  }
}
</script>

<style scoped>

</style>