import apiRequest from "@/util/apiRequest";
import _ from "lodash";

export default {
    getTodasHomologadas(dadosBusca) {
        return new Promise((resolve, reject) => {
            const filtragem = {};
            if (dadosBusca.page !== undefined) {
                filtragem.page = dadosBusca.page;
            }
            if (dadosBusca.itemsPerPage !== undefined) {
                filtragem.perPage = dadosBusca.itemsPerPage;
            }
            const filtro = dadosBusca.filtro;
            if (filtro) {
                if (!_.isEmpty(filtro.nome)) {
                    filtragem.nome = filtro.nome;
                }
                if (!_.isEmpty(filtro.padrao)) {
                    filtragem.padrao = filtro.padrao;
                }
            }

            let link = 'cidade/homologadas';
            if (!_.isEmpty(filtragem)) {
                const params = new URLSearchParams(filtragem);
                link += '?' + params.toString();
            }

            apiRequest.get(link).then((response) => {
                resolve(response.data);
            }).catch((error) => {
                reject(error);
            });
        });
    }
};