import apiRequest from "@/util/apiRequest";
import _ from "lodash";

export default {
    getTodosChamados(dadosBusca) {
        return new Promise((resolve, reject) => {
            const filtragem = {};
            if (dadosBusca.page !== undefined) {
                filtragem.page = dadosBusca.page;
            }
            if (dadosBusca.itemsPerPage !== undefined) {
                filtragem.perPage = dadosBusca.itemsPerPage;
            }
            const filtro = dadosBusca.filtro;
            if (filtro) {
                if (!_.isEmpty(filtro.assuntoSolicitacao)) {
                    filtragem.assunto_solicitacao = filtro.assuntoSolicitacao;
                }
                if (filtro.statusSolicitacao !== null) {
                    filtragem.status_solicitacao = filtro.statusSolicitacao;
                }
            }

            let link = 'suporte/chamados/buscar/todos';
            if (!_.isEmpty(filtragem)) {
                const params = new URLSearchParams(filtragem);
                link += '?' + params.toString();
            }

            apiRequest.get(link, process.env.VUE_APP_BASE_URL).then((response) => {
                resolve(response.data);
            }).catch((error) => {
                reject(error);
            });
        });
    },
    abrirChamado(data) {
        return new Promise((resolve, reject) => {
            apiRequest.post('suporte/chamados/abrir', data).then((response) => {
                resolve(response.data);
            }).catch((error) => {
                reject(error);
            });
        });
    },
    encerrarChamado(idChamado) {
        return new Promise((resolve, reject) => {
            apiRequest.get(`suporte/chamados/${idChamado}/encerrar`).then((response) => {
                resolve(response.data);
            }).catch((error) => {
                reject(error);
            });
        });
    },

    getTodasMensagens(buscarData) {
        return new Promise((resolve, reject) => {
            apiRequest.get(`suporte/chamados/${buscarData.idChamado}/mensagem?page=` + buscarData.page, process.env.VUE_APP_BASE_URL).then((response) => {
                resolve(response.data);
            }).catch((error) => {
                reject(error);
            });
        });
    },
    enviarMensagemChamado(idChamado, data) {
        return new Promise((resolve, reject) => {
            apiRequest.post(`suporte/chamados/${idChamado}/mensagem/enviar`, data).then((response) => {
                resolve(response.data);
            }).catch((error) => {
                reject(error);
            });
        });
    },

    getTemplates(tag) {
        return new Promise((resolve, reject) => {
            apiRequest.get(`suporte/chamados/templates?tag=` + tag).then((response) => {
                resolve(response.data);
            }).catch((error) => {
                reject(error);
            });
        });
    },
};