import apiRequest from "@/util/apiRequest";

export default {
    getTodasMensagens(payload) {
        return new Promise((resolve, reject) => {
            let link = `suporte/homologacao/${payload.idSolicitacao}/mensagem?page=${payload.page}`;

            apiRequest.get(link, process.env.VUE_APP_BASE_URL).then((response) => {
                resolve(response.data);
            }).catch((error) => {
                reject(error);
            });
        });
    },
    salvar(data) {
        return new Promise((resolve, reject) => {
            let link = `suporte/homologacao/${data.idSolicitacao}/mensagem/salvar`;

            apiRequest.post(
                link,
                data.mensagems).then((response) => {
                resolve(response.data);
            }).catch((error) => {
                reject(error);
            });

        });
    }
};