import moment from "moment";

export const formatErrorMessage = message => {
    if (Array.isArray(message)) {
        let retorno = '';
        message.forEach(m => {
            for (let attr in m.constraints) {
                retorno += m.constraints[attr] + '\n';
            }
        });
        return retorno;
    } else {
        return message;
    }
};

export const numberToReal = numero => Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(numero);

export const maxFractionDigits = (numero, maxDigits) => Intl.NumberFormat('pt-BR', {maximumFractionDigits: maxDigits}).format(numero);

export const getNameMonth = numberOfMonth => {
    const months = ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"];
    if (numberOfMonth && numberOfMonth > 0) {
        --numberOfMonth;
        return months[numberOfMonth];
    }
    return "";
};

export const formatDate = date => {
    moment.locale("pt-br");
    if (date) {
        return moment(date).format("DD/MM/YYYY LTS");
    }
    return "";
};

export const formataCPF = cpf => {
    cpf = cpf.replace(/[^\d]/g, "");
    return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
};

export const removeMaskCpf = cpf => cpf.replace(/[^\d]+/g, '');

export const readFile = file => {
    return new Promise((resolve, reject) => {
        try {
            const reader = new FileReader();
            reader.onloadend = e => {
                let encoded = e.target.result.toString().replace(/^data:(.*,)?/, '');
                if ((encoded.length % 4) > 0) {
                    encoded += '='.repeat(4 - (encoded.length % 4));
                }
                resolve(encoded);
            };
            reader.readAsDataURL(file);
        } catch (err) {
            reject(err);
        }
    });
};

export const gerarCpf = () => {
    const num1 = gerarNumeroAleatorio();
    const num2 = gerarNumeroAleatorio();
    const num3 = gerarNumeroAleatorio();
    const dig1 = digitos(num1, num2, num3);
    const dig2 = digitos(num1, num2, num3, dig1);

    return `${num1}${num2}${num3}${dig1}${dig2}`;
};

export const gerarNumeroAleatorio = () => {
    const aleat = Math.floor(Math.random() * 999);
    return ("" + aleat).padStart(3, '0');
};

export const digitos = (n1, n2, n3, n4) => {
    const nums = n1.split("").concat(n2.split(""), n3.split(""));

    if (n4 !== undefined) {
        nums[9] = n4;
    }

    let x = 0;
    for (let i = (n4 !== undefined ? 11 : 10), j = 0; i >= 2; i--, j++) {
        x += parseInt(nums[j]) * i;
    }

    const y = x % 11;
    return y < 2 ? 0 : 11 - y;
};

export const gerarSenha = () => {
    let senha = "";
    for (let i = 0; i < 12; i++) {
        senha += generatePassword();
    }
    return senha;

};

export const generatePassword = () => {
    let letrasSenha = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%&*#^~/;<>";
    return letrasSenha[Math.floor(Math.random() * letrasSenha.length)];
};

export const totalFiltrado = (filtro) => {
    let total = 0;
    Object.keys(filtro).forEach((key) => {
        if (filtro[key] !== null && filtro[key] !== '' && filtro[key] !== undefined) {
            total++;
        }
    });

    return total > 0 ? total : null;
};

export const removeEmpty = (obj) => {
    return Object.fromEntries(
        Object.entries(obj)
            // eslint-disable-next-line no-unused-vars
            .filter(([_, v]) => v != null)
            .map(([k, v]) => [k, v === Object(v) ? removeEmpty(v) : v])
    );
};

export const toBase64 = async (value) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(value);
        reader.onload = () => resolve(reader.result.toString().replace(/^data:(.*,)?/, ''));
        reader.onerror = (error) => reject(error);
    });
};

export default {};