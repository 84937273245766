<template>
  <div>
    <Template>
      <v-card elevation="0" class="card-border-color">
        <v-card-title class="px-4 pt-3">
          <h3>{{ inEdicao ? 'Dados da solicitação' : 'Solicitações de homologação' }}</h3>
          <v-col cols="12">
            <v-divider></v-divider>
          </v-col>
        </v-card-title>

        <v-card-text class="pt-0">
          <v-row>
            <v-col cols="12">
              <Listagem :style="inEdicao ? 'display: none;' : ''" :refresh="refreshList"></Listagem>
              <Edicao v-if="inEdicao" @onUpdate="refreshList = !refreshList"></Edicao>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </Template>
  </div>
</template>

<script>
import Template from "@/components/template/Template"
import Edicao from "@/components/solicitacaoHomologacao/Edicao"
import Listagem from "@/components/solicitacaoHomologacao/Listagem"

export default {
  components: {Template, Edicao, Listagem},
  name: "SolicitacaoHomologacao",
  data: () => ({
    inEdicao: false,
    refreshList: false
  }),
  watch: {
    $route(to) {
      this.inEdicao = to.hash === '#editar' || to.hash === '#criar';
      if (to.hash === '#criar') {
        this.$store.commit('solicitacaoHomologacao/setSolicitacaoHomologacaoEdicao', null);
      }
    }
  },
  mounted() {
    this.inEdicao = this.$route.hash === '#editar' || this.$route.hash === '#criar';
  }
}
</script>

<style scoped>

</style>