const state = () => ({
    menuExpand: false,
    error: null,
    success: null,
});

const getters = {
    getMenuExpand(state) {
        return state.menuExpand;
    },
    getError(state) {
        return state.error;
    },
    getSuccess(state) {
        return state.success;
    },

};

const actions = {
    changeMenuExpand({ commit }, payload) {
        commit("setMenuExpand", payload);
    },
    clearError({ commit }) {
        commit("setError", null);
    },
    clearSuccess({ commit }) {
        commit("setSuccess", null);
    }
};

const mutations = {
    setMenuExpand(state, payload) {
        state.menuExpand = payload;
    },
    setSuccess(state, payload) {
        state.success = payload;
    },
    setError(state, payload) {
        state.error = payload;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
