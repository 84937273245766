import EnderecoEntitie from "@/entities/enderecoEntitie";
import TelefoneEntities from "@/entities/telefoneEntitie";
import CertificadoEntitie from "@/entities/certificadoEntitie";
import NfseConfigEntitie from "@/entities/nfseConfigEntitie";

export default {
    id: null,
    endereco_principal: {... EnderecoEntitie},
    telefone_principal: {... TelefoneEntities},
    certificado_ativo: {... CertificadoEntitie},
    nfs_config: {... NfseConfigEntitie},
    nome_razao_social: null,
    nome_fantasia: null,
    photo_base64: null,
    tipo_conta: null,
    cnpj_cpf: null,
    insc_estadual: null,
    insc_estadual_st: null,
    insc_municipal: null,
    cnae_primario: null,
    regime_tributario: null,
    regime_especial_tributario: null,
    id_csc_producao: null,
    csc_producao: null,
    id_csc_homologacao: null,
    csc_homologacao: null,
    email: null,
    responsavel: null,
    observacoes: null,
    host: null,
    url_retorno: null,
};