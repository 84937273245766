import apiRequest from "@/util/apiRequest";
import _ from "lodash";

export default {
    getTodasSolicitacoes(dadosBusca) {
        return new Promise((resolve, reject) => {
            const filtragem = {};
            if (dadosBusca.page !== undefined) {
                filtragem.page = dadosBusca.page;
            }
            if (dadosBusca.itemsPerPage !== undefined) {
                filtragem.perPage = dadosBusca.itemsPerPage;
            }
            const filtro = dadosBusca.filtro;
            if (filtro) {
                if (!_.isEmpty(filtro.idCidade)) {
                    filtragem.id_cidade = filtro.idCidade;
                }
                if (filtro.statusSolicitacao !== null) {
                    filtragem.status_solicitacao = filtro.statusSolicitacao;
                }
            }

            let link = 'suporte/homologacao/buscar/todos';
            if (!_.isEmpty(filtragem)) {
                const params = new URLSearchParams(filtragem);
                link += '?' + params.toString();
            }

            apiRequest.get(link, process.env.VUE_APP_BASE_URL).then((response) => {
                resolve(response.data);
            }).catch((error) => {
                reject(error);
            });
        });
    },
    salvar(data) {
        return new Promise((resolve, reject) => {
            const isUpdate = data.id !== null;
            let link = 'suporte/homologacao/salvar';
            if (isUpdate) {
                link = 'suporte/homologacao/atualizar/' + data.id;
            }
            if (isUpdate) {
                apiRequest.put(
                    link,
                    data).then((response) => {
                    resolve(response.data);
                }).catch((error) => {
                    reject(error);
                });
            } else {
                apiRequest.post(
                    link,
                    data).then((response) => {
                    resolve(response.data);
                }).catch((error) => {
                    reject(error);
                });
            }
        });
    },
    encerrarSolicitacao(idChamado) {
        return new Promise((resolve, reject) => {
            apiRequest.get(`suporte/homologacao/${idChamado}/encerrar`).then((response) => {
                resolve(response.data);
            }).catch((error) => {
                reject(error);
            });
        });
    },
};