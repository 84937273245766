import relatorios from '@/api/relatorios';

// initial state
const state = () => ({
    documentosFiscais: null
});

// getters
const getters = {
    getDocumentosFiscais(state) {
        return state.documentosFiscais;
    }
};

// actions
const actions = {
    buscarRelatorioDocumentosFiscais({commit}) {
        relatorios.getBuscarDocumentosFiscais().then((result) => {
            commit('setDocumentosFiscais', result);
        }).catch((err) => {
            commit("core/setError", {
                titulo: 'Erro ao autenticar',
                mensagem: err.message
            }, {root: true});
        });
    }
};

// mutations
const mutations = {
    setDocumentosFiscais(state, documentosFiscais) {
        state.documentosFiscais = documentosFiscais;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};