import apiRequest from "@/util/apiRequest";
import _ from 'lodash';

export default {
    getTodas(dadosBusca) {
        return new Promise((resolve, reject) => {
            const filtragem = {};
            const header = {};
            if (dadosBusca.page !== undefined) {
                filtragem.page = dadosBusca.page;
            }
            if (dadosBusca.itemsPerPage !== undefined) {
                filtragem.perPage = dadosBusca.itemsPerPage;
            }
            const filtro = dadosBusca.filtro;
            if (filtro) {
                if (!_.isEmpty(filtro.nomeCliente)) {
                    filtragem.nome_cliente = filtro.nomeCliente;
                }
                if (filtro.ambiente !== null) {
                    filtragem.ambiente = filtro.ambiente;
                }
                if (filtro.status !== null) {
                    filtragem.nota_status = filtro.status;
                }
                if (!_.isEmpty(filtro.consumerId)) {
                    header['Consumer-Id'] = filtro.consumerId;
                }
            }


            let link = 'nfse';
            if (!_.isEmpty(filtragem)) {
                const params = new URLSearchParams(filtragem);
                link += '?' + params.toString();
            }

            apiRequest.get(link, process.env.VUE_APP_BASE_URL, header).then((response) => {
                resolve(response.data);
            }).catch((error) => {
                reject(error);
            });
        });
    }
};