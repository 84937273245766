import apiRequest from "@/util/apiRequest";
import _ from "lodash";

export default {
    getTodas(dadosBusca) {
        return new Promise((resolve, reject) => {
            const filtragem = {};
            if (dadosBusca.page !== undefined) {
                filtragem.page = dadosBusca.page;
            }
            if (dadosBusca.itemsPerPage !== undefined) {
                filtragem.perPage = dadosBusca.itemsPerPage;
            }
            const filtro = dadosBusca.filtro;
            if (filtro) {
                if (!_.isEmpty(filtro.nomeRazaoSocial)) {
                    filtragem.nome_razao_social = filtro.nomeRazaoSocial;
                }
                if (!_.isEmpty(filtro.cnpjCpf)) {
                    filtragem.cnpj_cpf = filtro.cnpjCpf;
                }
                if (!_.isEmpty(filtro.nomeFantasia)) {
                    filtragem.nome_fantasia = filtro.nomeFantasia;
                }
            }

            let link = 'empresa/todos';
            if (!_.isEmpty(filtragem)) {
                const params = new URLSearchParams(filtragem);
                link += '?' + params.toString();
            }

            apiRequest.get(link).then((response) => {
                resolve(response.data);
            }).catch((error) => {
                reject(error);
            });
        });
    },
    getMinhaEmpresa() {
        return new Promise((resolve, reject) => {
            apiRequest.get('empresa/logada').then((response) => {
                resolve(response.data);
            }).catch((error) => {
                reject(error);
            });
        });
    },
    salvar(data) {
        return new Promise((resolve, reject) => {
            const isUpdate = data.id !== null;
            let link = 'empresa/salvar';
            if (isUpdate) {
                link = 'empresa/atualizar/' + data.id;
            }
            if (isUpdate) {
                apiRequest.put(
                    link,
                    data).then((response) => {
                    resolve(response.data);
                }).catch((error) => {
                    reject(error);
                });
            } else {
                apiRequest.post(
                    link,
                    data).then((response) => {
                    resolve(response.data);
                }).catch((error) => {
                    reject(error);
                });
            }
        });
    }
};