import '@mdi/font/css/materialdesignicons.css';

import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    icons: {
        iconfont: "mdi"
    },
    theme: {
        themes: {
            light: {
                primary: '#235274', // #E53935
                secondary: "#99BC28", // #FFCDD2
                accent: "#448ABE", // #3F51B5,
                success: '#258325',
                warning: '#FF860D',
                error: '#F01A1A',
            },
        },
    }
});
