import Vue from 'vue';
import VueRouter from 'vue-router';
import AuthGuard from "./authGuard";

import Dashboard from '@/views/Dashboard.vue';
import Login from "@/views/Login";
import Softwarehouses from "@/views/admin/Softwarehouses";
import Nfse from "@/views/documentosFiscais/Nfse";
import Usuarios from "@/views/Usuarios";
import Webservices from "@/views/admin/Webservices";
import MeusDados from "@/views/dadosEmpresa/MeusDados";
import CertificadoDigital from "@/views/dadosEmpresa/CertificadoDigital";
import CidadesHomologadas from "@/views/CidadesHomologadas";
import Http404 from "@/views/http/Http404";
import SolicitacaoHomologacao from "@/views/SolicitacaoHomologacao";

Vue.use(VueRouter);

const routes = [
    {
        path: '/login',
        name: 'Login',
        component: Login,
        beforeEnter: AuthGuard
    },
    {
        path: '/',
        name: 'Dashboard',
        component: Dashboard,
        beforeEnter: AuthGuard
    },
    {
        path: '/softwarehouses',
        name: 'Softwarehouses',
        component: Softwarehouses,
        beforeEnter: AuthGuard
    },
    {
        path: '/nfse',
        name: 'NFSe',
        component: Nfse,
        beforeEnter: AuthGuard
    },
    {
        path: '/usuarios',
        name: 'Usuarios',
        component: Usuarios,
        beforeEnter: AuthGuard
    },
    {
        path: '/solicitacao-homologacao',
        name: 'Solicitacao Homologacao',
        component: SolicitacaoHomologacao,
        beforeEnter: AuthGuard
    },
    {
        path: '/webservices',
        name: 'Webservices',
        component: Webservices,
        beforeEnter: AuthGuard
    },
    {
        path: '/cidades-homologadas',
        name: 'Cidades Homologadas',
        component: CidadesHomologadas,
        beforeEnter: AuthGuard
    },
    {
        path: '/dados-empresa',
        name: 'Dados da empresa',
        component: MeusDados,
        beforeEnter: AuthGuard
    },
    {
        path: '/certificado-digital',
        name: 'Certificado digital',
        component: CertificadoDigital,
        beforeEnter: AuthGuard
    },
    {
        path: '/404',
        name: '404',
        component: Http404,
    },
    {
        path: "*",
        redirect: "/404"
    }
];

const router = new VueRouter({
    mode: 'history',
    routes
});

export default router;
