var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('ListagemOpcoes',{attrs:{"filtrados":_vm.totalFiltrado,"temFiltro":true,"filtroAberto":true}},[_c('v-row',[_c('v-col',{staticClass:"py-1",attrs:{"cols":"3"}},[_c('v-autocomplete',{attrs:{"search-input":_vm.empresaAutoCompleteText,"items":_vm.empresaAutoCompleteList,"loading":_vm.empresaAutoCompleteLoading,"item-text":_vm.nomeAutoComplete,"item-value":"consumer_id","outlined":"","dense":"","label":"Empresas","no-data-text":"Nenhuma empresa encontrada","cache-items":""},on:{"update:searchInput":function($event){_vm.empresaAutoCompleteText=$event},"update:search-input":function($event){_vm.empresaAutoCompleteText=$event}},model:{value:(_vm.filtro.consumerId),callback:function ($$v) {_vm.$set(_vm.filtro, "consumerId", $$v)},expression:"filtro.consumerId"}})],1),_c('v-col',{staticClass:"py-1",attrs:{"cols":"3"}},[_c('v-text-field',{attrs:{"label":"Nome cliente","outlined":"","dense":""},model:{value:(_vm.filtro.nomeCliente),callback:function ($$v) {_vm.$set(_vm.filtro, "nomeCliente", $$v)},expression:"filtro.nomeCliente"}})],1),_c('v-col',{staticClass:"py-1",attrs:{"cols":"3"}},[_c('v-select',{attrs:{"items":[{ name: 'Produção', id: 1 },{ name: 'Homologação', id: 0 } ],"item-value":"id","item-text":"name","clearable":"","dense":"","outlined":"","label":"Ambiente prefeitura","name":"ambiente"},model:{value:(_vm.filtro.ambiente),callback:function ($$v) {_vm.$set(_vm.filtro, "ambiente", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"filtro.ambiente"}})],1),_c('v-col',{staticClass:"py-1",attrs:{"cols":"3"}},[_c('v-select',{attrs:{"items":[
                    { name: 'Emitida', id: 1 },
                    { name: 'Cancelada', id: 2 },
                    { name: 'Processando', id: 3 },
                    { name: 'Aguardando prefeitura', id: 8 },
                    { name: 'Rejeitada', id: 4 },
                    { name: 'Erro interno', id: 5 } ],"item-value":"id","item-text":"name","dense":"","clearable":"","outlined":"","label":"Status","name":"status"},model:{value:(_vm.filtro.status),callback:function ($$v) {_vm.$set(_vm.filtro, "status", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"filtro.status"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"py-0 mb-2",attrs:{"cols":"12"}},[_c('v-btn',{staticClass:"mr-5",attrs:{"color":"primary","elevation":"2","small":"","loading":_vm.loading},on:{"click":function($event){return _vm.filtrarDados(false)}}},[_vm._v(" Filtrar ")]),_c('v-btn',{attrs:{"color":"default","elevation":"2","outlined":"","small":"","disabled":_vm.loading},on:{"click":function($event){return _vm.filtrarDados(true)}}},[_vm._v(" Limpar ")])],1)],1)],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.documentos,"options":_vm.options,"server-items-length":_vm.totalDocumentos,"loading":_vm.loading,"footer-props":{
            'items-per-page-options': [5, 10, 15, 30, 50]
          },"items-per-page":15,"item-key":"id","locale":"pt-BR"},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
          var item = ref.item;
return [_c('tr',[_c('td',[_vm._v(_vm._s(item.id))]),_c('td',[_vm._v(_vm._s(item.numeracao_rps))]),_c('td',[_vm._v(_vm._s(item.nota_numero !== null ? item.nota_numero : '0'))]),_c('td',[_vm._v(_vm._s(_vm.formatarData(item.updated_at)))]),_c('td',{staticStyle:{"max-width":"70px"}},[_c('v-tooltip',{attrs:{"bottom":"","transition":"fade-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"text-no-wrap text-truncate"},'div',attrs,false),on),[_vm._v(_vm._s(item.nome_cliente)+" ")])]}}],null,true)},[_c('label',[_vm._v(_vm._s(item.nome_cliente))])])],1),_c('td',[_c('money',_vm._b({attrs:{"readonly":""},model:{value:(item.valor_total_liquido),callback:function ($$v) {_vm.$set(item, "valor_total_liquido", $$v)},expression:"item.valor_total_liquido"}},'money',_vm.money,false))],1),_c('td',[_c('v-chip',{attrs:{"color":_vm.notaStatus[item.nota_status].color,"small":"","ripple":false}},[_vm._v(" "+_vm._s(_vm.notaStatus[item.nota_status].text)+" ")])],1),_c('td',[_c('v-menu',{attrs:{"offset-y":"","transition":"slide-y-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var attrs = ref.attrs;
          var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"white--text",attrs:{"color":"primary","icon":"","small":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-arrow-down-drop-circle-outline ")])],1)]}}],null,true)},[_c('v-list',[(item.nota_key)?_c('v-list-item',{attrs:{"link":"","dense":"","href":item.nota_key,"target":"_blank"}},[_c('v-list-item-title',[_c('v-icon',{attrs:{"small":"","color":"primary"}},[_vm._v("mdi-file-document-outline")]),_vm._v(" Baixar danfe ")],1)],1):_vm._e(),(item.nota_xml)?_c('v-list-item',{attrs:{"link":"","dense":"","href":item.nota_xml,"target":"_blank"}},[_c('v-list-item-title',[_c('v-icon',{attrs:{"small":"","color":"primary"}},[_vm._v("mdi-xml")]),_vm._v(" Baixar XML ")],1)],1):_vm._e(),(item.nota_xml_envio)?_c('v-list-item',{attrs:{"link":"","dense":"","href":item.nota_xml_envio,"target":"_blank"}},[_c('v-list-item-title',[_c('v-icon',{attrs:{"small":"","color":"primary"}},[_vm._v("mdi-xml")]),_vm._v(" Baixar XML de envio ")],1)],1):_vm._e()],1)],1)],1)])]}}])},[_c('v-progress-linear',{staticClass:"v-progress-linear--absolute",attrs:{"slot":"progress","color":"secondary","indeterminate":""},slot:"progress"})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }