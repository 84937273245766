<template>
  <v-app>
    <v-main>
      <router-view/>
      <v-snackbar v-model="notification.show"
                  :timeout="notification.timeout"
                  top color="secondary">

        {{ notification.text }}

        <template v-slot:action="{ attrs }">
          <v-btn text v-bind="attrs" @click="notification.show = false">
            Ok
          </v-btn>
        </template>
      </v-snackbar>
    </v-main>
  </v-app>
</template>
<script>
import Alert from "./util/alert";
import {mapGetters} from "vuex";
import {AbilityBuilder} from "@casl/ability";

export default {
  name: "App",
  data: () => ({
    notificationQueue: [],
    notification: {
      show: false,
      text: '',
      timeout: 5000
    },
  }),
  computed: {
    ...mapGetters({
      error: "core/getError",
      success: "core/getSuccess",
      authData: "authentication/getAuthData",
    }),
    hasNotificationsPending() {
      return this.notificationQueue.length > 0
    }
  },
  watch: {
    error(value) {
      if (value) {
        Alert.error(value.titulo, value.mensagem);
        this.$store.dispatch("core/clearError");
      }
    },
    success(value) {
      if (value) {
        this.addNotification(value);
        this.$store.dispatch("core/clearSuccess");
      }
    },
    'notification.show': function() {
      if(!this.notification.show && this.hasNotificationsPending) {
        this.notification = this.notificationQueue.shift()
        this.$nextTick(() => this.notification.show = true)
      }
    }
  },
  methods: {
    updateAbility() {
      if (this.authData) {
        const permissoes = this.authData.usuario.permissoes;
        const regras = this.authData.usuario.regras;

        const { can, rules } = new AbilityBuilder();

        permissoes.forEach((permissao) => {
          const perm = permissao.split('-');
          can(perm[1], perm[0]);
        });
        regras.forEach((regra) => {
          can('all', regra);
        });

        this.$ability.update(rules);
      }
    },
    addNotification(text, tempo = 5000) {
      this.notificationQueue.push({
        show: false,
        text: text,
        timeout: tempo
      })
      if(!this.notification.show) {
        this.notification = this.notificationQueue.shift()
        this.notification.show = true
      }
    }
  },
  created() {
    this.updateAbility();
  }
};
</script>
<style>
@import "./assets/styles.scss";
</style>
