<template>
  <div>
    <v-card>
      <v-row>
        <v-col cols="12">
        <v-col cols="12">
          <v-form ref="form" v-model="isValidForm">

            <v-col cols="12" class="py-0 mb-5" v-if="chamadoEncerado">
              <v-banner single-line>
                <v-icon slot="icon" color="warning" size="36">
                  mdi-text-box-remove-outline
                </v-icon>
                <b>Esta solicitação está encerrada!</b>
              </v-banner>
            </v-col>

            <v-col cols="12">
              <v-row>
                <v-col cols="5" class="py-1">
                  <v-text-field label="Razão Social" hint="Campo obrigatório"
                                persistent-hint outlined dense prepend-inner-icon="mdi-store"
                                v-model="solicitacaoHomologacao.razao_social"
                                counter maxlength="100"
                                :rules="[validations.required]"/>
                </v-col>
                <v-col cols="4" class="py-1">
                  <v-text-field label="CNPJ" hint="Campo obrigatório" persistent-hint outlined dense
                                v-model="solicitacaoHomologacao.cnpj" counter maxlength="18"
                                v-mask="['##.###.###/####-##']"
                                :rules="[validations.required]"/>
                </v-col>
                <v-col cols="3" class="py-1">
                  <v-text-field label="Inscrição Municipal" outlined dense counter maxlength="20"
                                v-model="solicitacaoHomologacao.insc_municipal"
                                hint="Campo obrigatório" persistent-hint :rules="[validations.required]"/>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="3" class="py-1">
                  <v-autocomplete
                      class="borderInput"
                      :items="tiposPrioridade"
                      v-model.trim="solicitacaoHomologacao.prioridade"
                      item-value="id"
                      item-text="name"
                      dense
                      outlined
                      label="Prioridade"
                      name="prioridade"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="3" class="py-1">
                  <v-autocomplete
                      class="borderInput"
                      :items="tiposNaturezaOperacao"
                      v-model.trim="solicitacaoHomologacao.natureza_operacao"
                      item-value="id"
                      item-text="name"
                      dense
                      outlined
                      hint="Campo obrigatório" persistent-hint :rules="[validations.required]"
                      label="Natureza Operação"
                      name="natureza_operacao"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="3" class="py-1">
                  <v-autocomplete
                      class="borderInput"
                      :items="tiposRegimeTributacao"
                      v-model.trim="solicitacaoHomologacao.regime_tributacao"
                      item-value="id"
                      item-text="name"
                      dense
                      outlined
                      hint="Campo obrigatório" persistent-hint
                      label="Regime de tributação"
                      name="regime_tributacao"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="3" class="py-1">
                  <v-autocomplete
                      class="borderInput"
                      :items="tiposTributacaoEspecial"
                      v-model.trim="solicitacaoHomologacao.regime_especial_tributacao"
                      item-value="id"
                      item-text="name"
                      dense
                      outlined
                      hint="Campo obrigatório" persistent-hint
                      label="Regime especial de tributação"
                      name="regime_especial_tributacao"
                  ></v-autocomplete>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="3" class="py-1">
                  <v-autocomplete
                      hint="Campo obrigatório" persistent-hint
                      :search-input.sync="cidadeAutoCompleteText"
                      :items="cidadeAutoCompleteList"
                      :loading="cidadeAutoCompleteLoading"
                      :rules="[() => !!cidadeAutoCompleteText || 'Preenchimento obrigatório']"
                      v-model="cidadeSelected"
                      item-text="nome"
                      item-value="codigo_ibge"
                      outlined dense
                      label="Cidade"
                      no-data-text="Nenhuma cidade encontrada"
                      prepend-inner-icon="mdi-city"
                      return-object
                  >
                  </v-autocomplete>
                </v-col>
                <v-col cols="4" class="py-1">
                  <v-text-field label="Link portal prefeitura" v-model="solicitacaoHomologacao.link_prefeitura"
                                outlined dense counter maxlength="255"
                                hint="Link no qual você acessa para emitir notas na prefeitura"
                                persistent-hint :rules="[validations.required]"/>
                </v-col>
                <v-col cols="3" class="py-1">
                  <v-text-field label="Usuário prefeitura" v-model="solicitacaoHomologacao.usuario_prefeitura"
                                outlined dense counter maxlength="100"
                                hint="Campo obrigatório" persistent-hint :rules="[validations.required]"/>
                </v-col>
                <v-col cols="2" class="py-1">
                  <v-text-field label="Senha prefeitura" v-model="solicitacaoHomologacao.senha_prefeitura"
                                outlined dense counter maxlength="65"
                                :append-icon="passwordShow ? 'mdi-eye' : 'mdi-eye-off'"
                                :type="passwordShow ? 'text' : 'password'"
                                @click:append="passwordShow = !passwordShow"
                                hint="Campo obrigatório" persistent-hint :rules="isEdit ? [] : [validations.required]"/>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="4" class="py-1">
                  <v-file-input prepend-icon="" counter
                                dense outlined
                                name="anexos" label="Certificado A1"
                                prepend-inner-icon="mdi-paperclip"
                                accept=".pfx"
                                hint="Campo obrigatório" persistent-hint :rules="isEdit ? [] : [validations.required]"
                                v-model="certificadoAnexo"/>
                </v-col>
                <v-col cols="4" class="py-1">
                  <v-text-field label="Senha certificado" v-model="solicitacaoHomologacao.senha_certificado"
                                outlined dense counter maxlength="65"
                                :append-icon="passwordCertificadoShow ? 'mdi-eye' : 'mdi-eye-off'"
                                :type="passwordCertificadoShow ? 'text' : 'password'"
                                @click:append="passwordCertificadoShow = !passwordCertificadoShow"
                                hint="Campo obrigatório" persistent-hint :rules="isEdit ? [] : [validations.required]"/>
                </v-col>
                <v-col cols="4" class="py-1">
                  <v-file-input prepend-icon="" counter
                                dense outlined
                                accept=".xml"
                                name="anexos" label="XML Exemplo"
                                prepend-inner-icon="mdi-paperclip"
                                v-model="xmlExemploAnexo"/>
                </v-col>
              </v-row>

              <v-row v-if="isEdit">
                <v-col cols="12" class="py-1">
                  <v-chip
                      color="primary"
                      link
                      v-if="this.solicitacaoHomologacao.path_certificado"
                      :href="this.solicitacaoHomologacao.path_certificado"
                      outlined
                      class="mr-5"
                  >Baixar certificado</v-chip>
                  <v-chip
                      color="primary"
                      link
                      v-if="this.solicitacaoHomologacao.path_xml"
                      :href="this.solicitacaoHomologacao.path_xml"
                      outlined
                  >Baixar XML</v-chip>
                </v-col>
              </v-row>
            </v-col>

            <v-col cols="12 mt-5">
              <v-btn class="mr-5" color="secondary" elevation="2" @click="salvar" :loading="salvarLoading"
                     v-if="!chamadoEncerado">Salvar
              </v-btn>
              <v-btn color="accent" elevation="2" outlined :disabled="salvarLoading" @click="cancelar">Cancelar</v-btn>
              <v-btn class="float-right my-2" color="red" elevation="2" outlined :disabled="salvarLoading"
                     @click="encerar" v-if="!chamadoEncerado" small>Encerrar Chamado
              </v-btn>
            </v-col>
          </v-form>
        </v-col>
        </v-col>
      </v-row>

    </v-card>

    <v-card class="mt-10 mb-5">
      <Mensagem v-if="solicitacaoHomologacao.id" :idSolicitacao="this.solicitacaoHomologacao.id" @onUpdate="refreshList = !refreshList"></Mensagem>
    </v-card>
  </div>
</template>


<script>
import {mapGetters} from "vuex";
import _ from "lodash";
import Validator from "@/util/validation";
import {toBase64} from "@/util/util";
import SolicitacaoHomologacaoEntitie from "@/entities/solicitacaoHomologacaoEntities";
import utilitarios from "@/api/utilitarios";
import Mensagem from '@/components/mensagens/Mensagens';

export default {
  name: "Edicao",
  components: {Mensagem},
  data: () => ({
    cidadeAutoCompleteText: '',
    cidadeAutoCompleteLoading: false,
    cidadeAutoCompleteList: [],
    cidadeSelected: {
      nome: '',
      id: ''
    },
    isValidForm: true,
    salvarLoading: false,
    isEdit: false,
    solicitacaoHomologacao: {...SolicitacaoHomologacaoEntitie},
    validations: {...Validator},
    certificadoAnexo: null,
    xmlExemploAnexo: null,
    passwordShow: false,
    passwordCertificadoShow: false,
    tiposRegimeTributacao: [
      {name: "Optante simples nacional", id: 1},
      {name: "Sujeito a tributação normal", id: 0},
    ],
    tiposNaturezaOperacao: [
      {name: "Tributação no município", id: 1},
      {name: "Tributação fora do municipio", id: 2},
      {name: "Isenção", id: 3},
      {name: "Imune", id: 4},
      {name: "Processo Judicial", id: 5},
    ],
    tiposTributacaoEspecial: [
      {name: "Micro-empresa Municipal", id: 1},
      {name: "Estimativa", id: 2},
      {name: "Sociedade de profissionais", id: 3},
      {name: "Cooperativa", id: 4},
      {name: "Micro-empresario individual MEI", id: 5},
      {name: "Micro-empresario e empresa de pequeno porte", id: 6},
      {name: "Não informado", id: 0},
    ],
    tiposPrioridade: [
      {name: "Baixa", id: 0},
      {name: "Média", id: 1},
      {name: "Alta", id: 2},
      {name: "Urgente", id: 3},
    ]
  }),
  computed: {
    ...mapGetters({
      solicitacaoHomologacaoEdicao: "solicitacaoHomologacao/getSolicitacaoHomologacaoEdicao",
    }),
    chamadoEncerado: function () {
      return this.solicitacaoHomologacao.status_solicitacao === 'Encerrado' || this.solicitacaoHomologacao.status_solicitacao === 'Rejeitado'
    }
  },
  watch: {
    certificadoAnexo: async function (value) {
      this.solicitacaoHomologacao.certificado = await toBase64(value);
    },
    xmlExemploAnexo: async function (value) {
      this.solicitacaoHomologacao.xml = await toBase64(value);
    },
    cidadeAutoCompleteText: function (value) {
      if (this.cidadeAutoCompleteLoading) {
        this.cidadeAutoCompleteLoading = false;
        return;
      }

      if (value && value.length >= 3) {
        this.cidadeAutoCompleteLoading = true;
        this.buscarCidade(value);
      }
    },
    cidadeSelected: function (cidade) {
      this.solicitacaoHomologacao.id_cidade = cidade.id
    },
  },
  methods: {
    salvar() {
      this.$refs['form'].validate();
      if (this.isValidForm) {
        this.salvarLoading = true;
        this.$store.dispatch('solicitacaoHomologacao/salvar', this.solicitacaoHomologacao).then((data) => {
          if (data) {
            const isUpdate = this.solicitacaoHomologacao.id !== null;
            this.$store.commit('core/setSuccess', isUpdate ? 'Atualizado com sucesso!' : 'Criado com sucesso!');
            this.$emit('onUpdate');
            this.cancelar();
          }
        }).finally(() => {
          this.salvarLoading = false;
        });
      }
    },
    encerar() {
      this.salvarLoading = true;
      this.$store.dispatch('solicitacaoHomologacao/encerrarChamado', {
        idSolicitacaoHomologacao: this.solicitacaoHomologacao.id
      }).finally(() => {
        this.$store.commit('core/setSuccess', 'Solicitação encerrada!');
        this.salvarLoading = false;
        this.$emit('onUpdate');
        this.cancelar();
      });
    },
    cancelar() {
      this.$router.push({hash: ''})
    },
    buscarCidade(cidade) {
      utilitarios.buscarCidade(cidade).then((res) => {
        this.cidadeAutoCompleteList = res.data;
      }).finally(() => {
        this.cidadeAutoCompleteLoading = false;
      });
    },
  },
  mounted() {
    if (this.solicitacaoHomologacaoEdicao) {
      this.isEdit = true;
      this.solicitacaoHomologacao = _.cloneDeep(this.solicitacaoHomologacaoEdicao);
      this.cidadeAutoCompleteLoading = true;
      this.cidadeSelected = {
        id: this.solicitacaoHomologacao.cidade.id,
        nome: this.solicitacaoHomologacao.cidade.nome
      };
      this.cidadeAutoCompleteText = this.cidadeSelected.nome;
      this.cidadeAutoCompleteList.push(this.cidadeSelected);
    }
  }
}
</script>

<style scoped>

</style>