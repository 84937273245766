import CidadeEntitie from "@/entities/cidadeEntitie";

export default {
    id: null,
    id_cidade: null,
    cidade: {...CidadeEntitie},
    razao_social: null,
    cnpj: null,
    insc_municipal: null,
    senha_certificado: null,
    usuario_prefeitura: null,
    senha_prefeitura: null,
    link_prefeitura: null,
    natureza_operacao: null,
    regime_tributacao: 0,
    regime_especial_tributacao: 0,
    certificado: null,
    xml: null,
    path_certificado: null,
    path_xml: null,
    status_solicitacao: null,
    prioridade: 0,
    data_abertura: null,
    data_encerramento: null,
};