var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('ListagemOpcoes',{attrs:{"adicionarNovo":true}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-1",staticStyle:{"cursor":"pointer"},attrs:{"headers":_vm.headers,"items":_vm.items,"options":_vm.options,"server-items-length":_vm.totalItems,"loading":_vm.loading,"footer-props":{
            'items-per-page-options': [5, 10, 15, 30, 50]
          },"items-per-page":15,"item-key":"id","locale":"pt-BR"},on:{"update:options":function($event){_vm.options=$event},"click:row":_vm.rowClick},scopedSlots:_vm._u([{key:"item",fn:function(ref){
          var item = ref.item;
return [_c('tr',{on:{"click":function($event){return _vm.rowClick(item)}}},[_c('td',[_vm._v(_vm._s(item.id))]),_c('td',[_vm._v(_vm._s(item.nome))]),_c('td',[_vm._v(_vm._s(item.email))]),_c('td',[_vm._v(_vm._s(item.login))]),_c('td',[_c('v-chip',{attrs:{"color":item.status ? 'success' : 'error',"small":"","ripple":false}},[_vm._v(" "+_vm._s(item.status ? 'Ativo' : 'Inativo')+" ")])],1),_c('td',{attrs:{"align":"right"}},[_c('v-menu',{attrs:{"offset-y":"","transition":"slide-y-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var attrs = ref.attrs;
          var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"white--text",attrs:{"color":"primary","icon":"","small":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-arrow-down-drop-circle-outline ")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{attrs:{"link":"","dense":""},on:{"click":function($event){return _vm.deletarUsuario(item)}}},[_c('v-list-item-title',[_c('v-icon',{attrs:{"small":"","color":"primary"}},[_vm._v("mdi-delete-outline")]),_vm._v(" Deletar ")],1)],1)],1)],1)],1)])]}}])},[_c('v-progress-linear',{staticClass:"v-progress-linear--absolute",attrs:{"slot":"progress","color":"secondary","indeterminate":""},slot:"progress"})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }