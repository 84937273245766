import mensagem from '@/api/mensagem';

// initial state
const state = () => ({
    totalMensagens: 0,
    mensagens: [],
    totalPaginas: 0,
});

// getters
const getters = {
    getMensagens(state) {
        return state.mensagens;
    },
    getTotalMensagens(state) {
        return state.totalMensagens;
    },
    getTotalPaginas(state){
        return state.totalPaginas;
    }
};

// actions
const actions = {
    salvar({commit}, payload) {
        return new Promise((resolve, reject) => {
            mensagem.salvar(payload)
                .then((result) => {
                    commit("setNewMensagem", result.data)
                    resolve(result.data)
                })
                .catch((err) => {
                    commit("core/setError", {
                        titulo: 'Erro ao mandar a mensagem',
                        mensagem: err.data.message
                    }, {root: true});
                    reject(err)
                });
        })
    },
    buscarTodasMensagens({commit}, payload) {
        return mensagem.getTodasMensagens(payload).then((result) => {
            commit('setMensagens', result.data);
            commit('setTotalMensagens', result.meta.total);
            commit('setTotalPaginas', result.meta.last_page)
        }).catch(() => {
            commit('setMensagens', []);
            commit('setTotalMensagens', 0);
        });
    },
};

// mutations
const mutations = {
    setMensagens(state, value) {
        state.mensagens = value;
    },
    setNewMensagem(state, value) {
        state.mensagens.unshift(value)
    },
    setTotalMensagens(state, value) {
        state.totalMensagens = value;
    },
    setTotalPaginas(state, value) {
        state.totalPaginas = value;
    },

};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};