<template>
  <div>
    <v-row>
      <v-col cols="12" class="py-0">
        <ListagemOpcoes :adicionarNovo="true"/>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-data-table
            style="cursor: pointer"
            :headers="headers"
            :items="softwarehouses"
            :options.sync="options"
            :server-items-length="totalSoftwarehouses"
            :loading="loading"
            :footer-props="{
              'items-per-page-options': [5, 10, 15, 30, 50]
            }"
            :items-per-page="15"
            @click:row="rowClick"
            item-key="id"
            locale="pt-BR"
            class="elevation-1">
          <v-progress-linear slot="progress" color="secondary" indeterminate class="v-progress-linear--absolute"></v-progress-linear>
          <template v-slot:item.pdf="{item}">
            <a @click.stop target="__blank" :href="item.acesso_pdf" v-if="item.acesso_pdf !== ''">
              <v-icon medium>mdi-download-circle</v-icon>
            </a>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

  </div>
</template>

<script>
import {mapGetters} from "vuex";
import SoftwarehouseEntitie from "@/entities/softwarehouseEntitie";
import ListagemOpcoes from "@/components/generic/ListagemOpcoes";

export default {
  name: "Listagem",
  props: ['refresh'],
  components: {ListagemOpcoes},
  data: () => ({
    loading: false,
    options: {
      page: 1,
      itemsPerPage: 15
    },
    headers: [
      {
        text: "#",
        value: "id",
        sortable: false,
        align: "left"
      },
      {
        text: "Empresa",
        value: "nome_razao_social",
        sortable: false,
        align: "center",
      },
      {
        text: "E-mail",
        value: "email",
        sortable: false,
        align: "center",
      },
      {
        text: "Status",
        value: "status",
        sortable: false,
        align: "center",
      },
      {
        text: "PDF",
        value: "pdf",
        align: "center"
      }
    ],
  }),
  computed: {
    ...mapGetters({
      softwarehouses: "softwarehouse/getSoftwarehouses",
      totalSoftwarehouses: "softwarehouse/getTotalSoftwarehouses"
    })
  },
  watch: {
    refresh: function () {
      this.buscarSoftwarehouses();
    },
    options: {
      handler(){
        this.buscarSoftwarehouses();
      },
      deep: true
    }
  },
  methods: {
    buscarSoftwarehouses() {
      this.loading = true;
      this.$store.dispatch("softwarehouse/buscarTodas", this.options).finally(() => this.loading = false)
    },
    rowClick(row = {...SoftwarehouseEntitie}) {
      row.endereco_principal = row.endereco;
      row.usuario_administrador = null;
      delete row.endereco;

      this.$store.commit('softwarehouse/setSoftwarehouseEdicao', row);
      this.$router.push({hash: '#editar'})
    }
  }
}
</script>

<style scoped>

</style>