<template>
  <div>
    <Template>
      <v-card elevation="0" class="card-border-color">
        <v-card-title class="px-4 pt-3">
          <h3>Dados da empresa</h3>
          <v-col cols="12">
            <v-divider></v-divider>
          </v-col>
        </v-card-title>

        <v-card-text class="pt-0">
          <v-row>
            <v-col cols="12" v-if="isLoaded">
              <Edicao :fromMeusDados="true"></Edicao>
            </v-col>
            <v-col cols="12" v-else>
              <v-skeleton-loader class="mx-auto" type="text"></v-skeleton-loader>
              <v-skeleton-loader class="mx-auto" type="text"></v-skeleton-loader>
              <v-skeleton-loader class="mx-auto" type="text"></v-skeleton-loader>
              <v-skeleton-loader class="mx-auto" type="text"></v-skeleton-loader>
              <v-skeleton-loader class="mx-auto" type="text"></v-skeleton-loader>
              <v-skeleton-loader class="mx-auto" type="text"></v-skeleton-loader>
              <v-skeleton-loader class="mx-auto" type="text"></v-skeleton-loader>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </Template>
  </div>
</template>

<script>
import Template from "@/components/template/Template"
import Edicao from "@/components/empresas/Edicao";

export default {
  components: {Template, Edicao},
  name: "MeusDados",
  data: () => ({
    isLoaded: false
  }),
  mounted() {
    this.$store.dispatch('empresa/minhaEmpresa').finally(() => {
      this.isLoaded = true;
    });
  }
}
</script>

<style scoped>

</style>