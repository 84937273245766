import axios from "axios";
import store from "@/store";

export default function setup() {
    axios.interceptors.request.use(
        config => {
            const authData = store.state.authentication.authData;
            if (authData) {
                let token = authData.access_token;
                config.headers.Authorization = `Bearer ${token}`;
            }
            config.headers['Content-Type'] = 'application/json';

            return config;
        },
        err => Promise.reject(err)
    );
    axios.interceptors.response.use(
        response => {
            return response;
        },
        error => {
            return Promise.reject(error.response.data);
        }
    );
}
