<template>
  <div>
    <v-row>
      <v-col cols="12" class="py-0">
        <ListagemOpcoes :adicionarNovo="true"/>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-data-table
            style="cursor: pointer"
            :headers="headers"
            :items="items"
            :options.sync="options"
            :server-items-length="totalItems"
            :loading="loading"
            :footer-props="{
              'items-per-page-options': [5, 10, 15, 30, 50]
            }"
            :items-per-page="15"
            @click:row="rowClick"
            item-key="id"
            locale="pt-BR"
            class="elevation-1">
          <v-progress-linear slot="progress" color="secondary" indeterminate class="v-progress-linear--absolute"></v-progress-linear>
          <template v-slot:item="{ item }">
            <tr @click="rowClick(item)">
              <td>{{ item.id }}</td>
              <td>{{ item.nome }}</td>
              <td>{{ item.email }}</td>
              <td>{{ item.login }}</td>
              <td>
                <v-chip :color="item.status ? 'success' : 'error'" small :ripple="false">
                  {{ item.status ? 'Ativo' : 'Inativo' }}
                </v-chip>
              </td>
              <td align="right">
                <v-menu offset-y transition="slide-y-transition">
                  <template v-slot:activator="{ attrs, on }">
                    <v-btn color="primary" icon small class="white--text" v-bind="attrs" v-on="on">
                      <v-icon>
                        mdi-arrow-down-drop-circle-outline
                      </v-icon>
                    </v-btn>
                  </template>

                  <v-list>
                    <v-list-item link dense @click="deletarUsuario(item)">
                      <v-list-item-title>
                        <v-icon small color="primary">mdi-delete-outline</v-icon>
                        Deletar
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import _ from 'lodash';
import ListagemOpcoes from "@/components/generic/ListagemOpcoes";
import UsuarioEntitie from '@/entities/usuarioEntitie';
import TelefoneEntitie from '@/entities/telefoneEntitie';


export default {
  name: "Listagem",
  components: {ListagemOpcoes},
  props: ['refresh'],
  data: () => ({
    loading: false,
    options: {
      page: 1,
      itemsPerPage: 15
    },
    headers: [
      {
        text: "#",
        value: "id",
        sortable: false,
        align: "left"
      },
      {
        text: "Nome",
        value: "nome",
        sortable: false,
      },
      {
        text: "E-mail",
        value: "email",
        sortable: false,
      },
      {
        text: "Login",
        value: "login",
      },
      {
        text: "Status",
        value: "status",
        sortable: false,
      },
      {
        text: "Ações",
        value: "acoes",
        align: "right"
      }
    ]
  }),
  computed: {
    ...mapGetters({
      items: "usuarios/getUsuarios",
      totalItems: "usuarios/getTotalUsuarios"
    })
  },
  watch: {
    refresh: function () {
      this.buscarDados();
    },
    options: {
      handler(){
        this.buscarDados();
      },
      deep: true
    }
  },
  methods: {
    buscarDados() {
      this.loading = true;
      this.$store.dispatch("usuarios/buscarTodas", this.options).finally(() => this.loading = false)
    },
    rowClick(row = {... UsuarioEntitie}) {
      const usuario = _.cloneDeep(row);
      if (usuario.telefone === null) {
        usuario.telefone = {... TelefoneEntitie}
      }
      if (usuario.role) {
        usuario.role = {
          id: usuario.role[0].id,
          display_name: usuario.role[0].display_name,
        }
      }

      this.$store.commit('usuarios/setUsuarioEdicao', usuario);
      this.$router.push({hash: '#editar'})
    },
    deletarUsuario(item) {
      this.loading = true;
      this.$store.dispatch('usuarios/remover', item).finally(() => {
        this.loading = false;
      });
    }
  }
}
</script>

<style scoped>

</style>